export enum PositionState {
  top = "top",
  topLeft = "top-left",
  topRight = "top-right",
  bottom = "bottom",
  bottomLeft = "bottom-left",
  bottomRight = "bottom-right",
  left = "left",
  leftTop = "left-top",
  leftBottom = "left-bottom",
  right = "right",
  rightTop = "right-top",
  rightBottom = "right-bottom",
}

export interface TooltipOptions {
  toolTipText?: string | any;
  tooltipKeyboardFocusEl?: HTMLElement;
  tooltipInvokeType?: InvokeType | string;
  tooltipType?: TooltipType | string;
  tooltipPosition?: PositionState | string;
  tooltipIndent?: number;
  tooltipWidth?: string | number;
  tooltipMaxWidth?: string | number;
  tooltipShift?: number;
  tooltipHasBeak?: boolean;
  tooltipIsDisabled?: boolean;
  tooltipIsOutsideClick?: boolean;
  tooltipIsDynamic?: boolean;

  id?: string;
  custStyle?: any;
  children?: React.ReactNode;
  customclass?: string;
}

export enum TooltipType {
  regular = "regular",
  error = "error",
}

export enum TooltipClass {
  tooltip = "tooltip",
  tooltipLarge = "tooltip_lg",
  error = "tooltip_error",
}

export enum InvokeType {
  click = "click",
  hover = "hover",
  hoverClick = "hoverClick",
}
