import * as React from "react";
import "./ProgressBar.scss";
import { format_HR_MIN_SEC } from "global/utils/DateUtil";
import { debounce } from "global/utils/debounce";
interface IProgressBarProps {
  data: Record<string, number>;
  totalHrs: number;
  legends: { label: string; color: string }[];
  convertion?: number;
  isTooltip?: boolean;
  isCLickable?: boolean;
}
const BASE_CLASS = "progressBar";
const ProgressBar: React.FunctionComponent<IProgressBarProps> = ({
  data,
  totalHrs,
  legends,
  convertion = 1,
  isTooltip = true,
  isCLickable,
}) => {
  const tooltipRef: any = React.useRef<HTMLDivElement>();
  const isShowTooltip = legends.reduce((a, b) => a + data[b.label], 0) > 0;

  const setPosition = debounce((e: any) => {
    if (isTooltip && isShowTooltip) {
      const breakX =
        window.screen.width -
        (document.getElementById("progressRef") as any).getBoundingClientRect()
          .right +
        200;
      if (window.screen.width - e.clientX < breakX) {
        tooltipRef.current.style.left = window.screen.width - breakX + "px";
      } else {
        tooltipRef.current.style.left = e.clientX + "px";
      }
      if (window.screen.height - e.clientY < 300) {
        tooltipRef.current.style.top = "auto";
        tooltipRef.current.style.bottom =
          window.screen.height - e.clientY - 150 + "px";
      } else {
        tooltipRef.current.style.bottom = "auto";
        tooltipRef.current.style.top = e.clientY + 20 + "px";
      }
    }
  }, 0);

  const modifyStyle = (val: string) => {
    if (isTooltip && isShowTooltip) {
      tooltipRef.current.style.display = val;
    }
  };

  return (
    <>
      <div
        id="progressRef"
        data-testid="progressbar"
        className={`${BASE_CLASS}-progress ${isCLickable && "cursor"}`}
        style={{ maxWidth: "100%" }}
        onMouseMove={(e) => setPosition(e)}
        onMouseEnter={() => modifyStyle("block")}
        onMouseLeave={() => modifyStyle("none")}
      >
        {legends.map((legend) => (
          <div
            key={legend.label}
            className={`${BASE_CLASS}-progress-bar`}
            style={{
              backgroundColor: totalHrs === 0 ? "" : legend.color,
              width: `${(data[legend.label] / totalHrs) * 100}%`,
            }}
            data-testid={legend.color}
          />
        ))}
      </div>
      {tooltipRef && isTooltip && isShowTooltip && (
        <div
          ref={tooltipRef}
          id="toottipRef"
          data-testid="tooltipRef"
          style={{ display: "none" }}
          className={`${Object.keys(data).length && `${BASE_CLASS}-tooltip`}`}
        >
          {legends.map((legend) => {
            if (+data[legend.label] > 0) {
              return (
                <div
                  key={legend.label}
                  style={{
                    color: legend.color,
                  }}
                >
                  {`${
                    legend.label === "Uncategorized"
                      ? "Uncategorised"
                      : legend.label
                  } - ${format_HR_MIN_SEC(+data[legend.label] / convertion)}`}
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default ProgressBar;
