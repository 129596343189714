import { FC } from "react";
import "./PowerBIDashboard.scss";
import { powerbiURL } from "serviceClient";

const BASE_CLASS = "powerBiDashboard";
const PowerBIDashboard: FC = () => {
  return (
    <>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}-content`}>
          <iframe className="powerBi-iframe" title="PowerBi Dashboard" width="1140" height="541.25" src={powerbiURL} frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </>
  );
};
export default PowerBIDashboard;
