import * as act from "./action";

interface StateInterface {
  notify: {
    type: "ERROR" | "WARNING" | "SUCCESS" | "DEFAULT";
    msg: string;
    timeout: number;
  };
  blockscreenMsg: string;
  showCoachMarks: boolean;
  loginUser: Record<string, any>;
  categories: Record<string, any>[];
  masterCategories: string[] | Record<string, any>[];
}

const initialState: StateInterface = {
  notify: {
    type: "DEFAULT",
    msg: "",
    timeout: 3000,
  },
  blockscreenMsg: "",
  showCoachMarks: false,
  loginUser: {},
  categories: [],
  masterCategories: ["Productive", "UnProductive", "Neutral"],
};

type actionType = {
  type: string;
  payload: any;
};

export default function globalReducer(
  state = initialState,
  action: actionType
) {
  switch (action.type) {
    case act.CALL_NOTIFY:
      return { ...state, notify: action.payload };
    case act.SHOWSCREENBLOCKMSG:
      return { ...state, blockscreenMsg: action.payload };
    case act.SHOWCOACHMARKS:
      return { ...state, showCoachMarks: action.payload };
    case act.LOGIN_USER:
      return { ...state, loginUser: action.payload };
    case act.LOGIN_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
}
