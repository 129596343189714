import { ThemeObject } from "@usitsdasdesign/dds-react";
import React, { ReactNode, createContext } from "react";
const themeObjState = {
  ...ThemeObject,
  colors: { ...ThemeObject.colors, Accessible_Green: "#0D8390" },
};
export const ThemeContext = createContext({ themeObjState });

export const ThemeProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <ThemeContext.Provider
      value={{
        themeObjState,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
