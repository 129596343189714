import { usersClient } from "../serviceClient";
type UserRecord = Record<string, string>;

const getAll = (params?: Record<string, any>) => {
  return usersClient.get<Array<UserRecord>>(
    "/users?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  );
};

const get = (id: any) => {
  return usersClient.get<IUser>(`/users/${id}`);
};

const create = (data: IUser) => {
  return usersClient.post<IUser>("/users", data);
};

const update = (id: any, data: IUser) => {
  return usersClient.put<any>(`/users/${id}`, data);
};

const remove = (id: any) => {
  return usersClient.delete<any>(`/users/${id}`);
};

const removeAll = () => {
  return usersClient.delete<any>(`/users`);
};

const findByNameorEmail = (name: string) => {
  return usersClient.get<Array<IUser>>(`/users?searchtext=${name}`);
};
const findByEmail = (email: string) => {
  return usersClient.get<Array<IUser>>(`/users?email=${email}`);
};

const UserService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByNameorEmail,
  findByEmail,
};

export default UserService;
