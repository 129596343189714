import { usersClient } from "../serviceClient";
export const getEvents = (params?: Record<string, any>) => {
  return usersClient.get<Array<any>>(
    "/users?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  );
};