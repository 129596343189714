import React, { memo, useEffect, useState } from "react";
import PageLink from "./components/pageLisk/PageLink";
import "./Pagination.scss";
import { getPaginationItems } from "./components/PaginationUtil";
import { NextIcon, PreviousIcon } from "../..";

export type PaginationProps = {
  currentPage: number;
  lastPage: number;
  maxLength?: number;
  setCurrentPage: (page: number) => void;
};

const Pagination = ({
  currentPage,
  lastPage,
  maxLength = 7,
  setCurrentPage,
}: PaginationProps) => {
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  const [page, setPage] = useState<number>(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);
  if (lastPage > 1) {
    return (
      <nav className="pagination no_highlights" aria-label="Pagination">
        <PageLink
          data-testid="previous-page-button"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <img src={PreviousIcon} alt="" />
        </PageLink>
        {pageNums.map((pageNum, idx) => (
          <PageLink
            key={idx}
            active={currentPage === pageNum}
            disabled={isNaN(pageNum)}
            onClick={() => setCurrentPage(pageNum)}
          >
            {!isNaN(pageNum) ? pageNum : "..."}
          </PageLink>
        ))}
        <PageLink
          data-testid="next-page-button"
          disabled={currentPage === lastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <img src={NextIcon} alt="" />
        </PageLink>

        <span className="pagination-text">Go to page</span>

        <input
          data-testid="pagination-input"
          className="pagination-input"
          value={page}
          onChange={(e) =>
            +e.target.value <= lastPage && setPage(+e.target.value)
          }
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && page <= lastPage) {
              if (page === 0) {
                setPage(page + 1);
                setCurrentPage(page + 1);
              } else {
                setCurrentPage(page);
              }
            }
          }}
        />
      </nav>
    );
  } else {
    return <></>;
  }
};

export default memo(Pagination);
