import { useEffect, useState } from "react";
// import { Provider } from "react-redux";
// import { PersistGate } from 'redux-persist/integration/react';
import { Route, Routes, useNavigate } from "react-router-dom";
import { HomePageContainer } from "./home/HomePageContainer";

import "./App.css";
import AuthService from "services/AuthService";
import UserService from "services/UserService";
import { ThemeProvider } from "theme/themeContext";
import { BlockScreenMsg, Notify } from "global";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_USER } from "global/store/action";
import { RootState } from "app/store";
import AccessDenied from "home/components/accessDenied/AccessDenied";
import { roleEnum } from "global/constants/Enums";

const validate = () => {
  if (window.localStorage["refreshToken"]) {
    const refreshToken = JSON.parse(
      window.atob(window.localStorage["refreshToken"].split(".")[1])
    );
    const dateNow = new Date();
    if (refreshToken.exp * 1000 < dateNow.getTime()) {
      window.localStorage.clear();
      return false;
    }
    return true;
  } else return false;
};

function App() {
  const [userRole, setUserRole] = useState("");
  const [isAccessAvailable, setIsAccessAvailable] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams) {
      ssoLogin(url.searchParams).then(() => {
        const isLoggedIn = validate();
        if (!isLoggedIn) {
          window.location.href = AuthService.getAuthenticationURL();
        } else {
          try {
            UserService.getAll({
              email: JSON.parse(window.localStorage["kcUserData"]).email,
            })
              .then((res: any) => {
                const tempData: IUser = {
                  ...res.data.data[0],
                };

                let tempRole: string = roleEnum.ENTITLED;
                tempData?.entitlements?.levels?.some((item: any) => {
                  if (
                    item.functionalArea === "*" &&
                    item.department === "*" &&
                    item.subDepartment === "*"
                  ) {
                    tempRole = roleEnum.MASTER;
                    setIsAccessAvailable(true);

                    return true;
                  } else if (
                    item.functionalArea === "" &&
                    item.department === "" &&
                    item.subDepartment === ""
                  ) {
                    tempRole = roleEnum.USER;
                    setIsAccessAvailable(false);

                    return true;
                  } else if (
                    item.functionalArea !== "" ||
                    item.department !== "" ||
                    item.subDepartment !== ""
                  ) {
                    tempRole = roleEnum.ENTITLED;
                    setIsAccessAvailable(true);

                    return true;
                  }
                  return false;
                });

                localStorage.setItem("encRecord", tempData?.encRecord || "");
                setUserRole(tempRole);
                dispatch({
                  type: LOGIN_USER,
                  payload: { ...tempData, role: tempRole },
                });
                navigate("/home");
              })
              .catch((e: any) => {
                if (e?.response?.status === 403) {
                  setIsAccessAvailable(false);
                  navigate("/access-denied");
                }
              });
          } catch (error) {}
        }
      });
    }
  }, []);

  const ssoLogin: any = async (urlSearchParams: any) => {
    if (urlSearchParams.has("code")) {
      const code = urlSearchParams.get("code");
      const loginStatus = await AuthService.ssoLogin(code);
      return loginStatus;
    } else return 0;
  };
  const isLoggedIn = validate();
  const blockMsg = useSelector(
    (state: RootState) => state.globalReducer.blockscreenMsg
  );
  return !(isLoggedIn && userRole) ? (
    isAccessAvailable ? (
      <></>
    ) : (
      <ThemeProvider>
        <div
          className={blockMsg ? "container" : ""}
          style={{ width: "100%", height: "100%" }}
        >
          <Routes>
            <Route path="/*" element={<AccessDenied />} />
          </Routes>
        </div>
      </ThemeProvider>
    )
  ) : (
    <ThemeProvider>
      <div
        className={blockMsg ? "container" : ""}
        style={{ width: "100%", height: "100%" }}
      >
        <Routes>
          {isAccessAvailable ? (
            <>
              <Route
                path="/home"
                element={
                  <>
                    <div id="tooltip-container"></div> <HomePageContainer />{" "}
                  </>
                }
              />
              <Route
                path="/*"
                element={
                  <>
                    <div id="tooltip-container"></div> <HomePageContainer />{" "}
                  </>
                }
              />
            </>
          ) : (
            <Route path="/*" element={<AccessDenied />} />
          )}
          {/* <Route
            path="/usermanagement"
            element={
              <PrivateRoute roles={['admin']} mongoData={mongoUserData}>
                <ProjectsContainer kcUserData={kcUserData} mongoUserData={mongoUserData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportingmanagement"
            element={
              <PrivateRoute roles={['bu']} mongoData={mongoUserData}>
                <GraphPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/categorymanagement"
            element={
              <PrivateRoute roles={['bu']} mongoData={mongoUserData}>
                <VariantPage />
              </PrivateRoute>
            }
          /> */}
        </Routes>
        <Notify />
        <BlockScreenMsg />
      </div>
    </ThemeProvider>
  );
}

export default App;
