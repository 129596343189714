import { FC } from "react";
import { ScreenBlock, Button, CloseIconSvg } from "../..";
import "./PopUpModel.scss";

interface PopUpModelInterface {
  Title: string;
  onClickClose: () => void;
  onSaveClick?: () => void;
  disabledSave?: boolean;
  screenBlock?: boolean;
  saveTitle?: string;
  closeTitle?: string;
  Footer?: FC;
  headerStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  children: React.ReactNode;
  closeButtonClass?: string;
  saveButtonClass?: string;
  popupModelMainStyle?: React.CSSProperties;
}

export const PopUpModel: FC<PopUpModelInterface> = (props) => (
  <>
    {props.screenBlock === false ? (
      <ModelComp {...props} />
    ) : (
      <ScreenBlock>
        <ModelComp {...props} />
      </ScreenBlock>
    )}
  </>
);
const ModelComp: FC<PopUpModelInterface> = ({
  children,
  Title,
  onClickClose,
  onSaveClick,
  disabledSave = false,
  saveTitle = "Save",
  closeTitle = "Close",
  Footer = null,
  headerStyle,
  footerStyle,
  closeButtonClass = "",
  saveButtonClass = "",
  popupModelMainStyle,
}) => {
  return (
    <div
      data-testid="test-main"
      className="popup-main"
      style={popupModelMainStyle || undefined}
    >
      <div className="--header">
        <div className="--title-head dds-h6" style={headerStyle || undefined}>
          {Title}
        </div>
        <CloseIconSvg
          className="close-action-item-popup"
          onClick={onClickClose}
        ></CloseIconSvg>
      </div>

      <div className="--contentbody">{children}</div>
      {Footer ? (
        <Footer />
      ) : (
        <div className="--footer" style={footerStyle || undefined}>
          {onSaveClick ? (
            <Button
              disabled={disabledSave}
              className={saveButtonClass ? saveButtonClass : "--testsave"}
              title={saveTitle}
              onClick={() => onSaveClick()}
            ></Button>
          ) : (
            ""
          )}
          <Button
            className={closeButtonClass ? closeButtonClass : "--testcancel"}
            title={closeTitle}
            onClick={() => onClickClose()}
          ></Button>
        </div>
      )}
    </div>
  );
};
