import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseIconSvg,
  CloseWhiteIconSvg,
  ErrorMsgIcon,
  SuccessIconSvg,
  WarningIcoSvg,
} from "global";
import { CALL_NOTIFY } from "global/store/action";
import "./Notify.scss";
import { RootState } from "app/store";

export const Notify: FC = () => {
  const notify = useSelector((state: RootState) => state.globalReducer.notify);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const color: any = useMemo(
    () => ({
      SUCCESS: {
        svg: SuccessIconSvg,
        close: CloseWhiteIconSvg,
      },
      ERROR: {
        svg: ErrorMsgIcon,
        close: CloseWhiteIconSvg,
      },
      WARNING: {
        svg: WarningIcoSvg,
        close: CloseIconSvg,
      },
      INFO: {
        svg: SuccessIconSvg,
        close: CloseWhiteIconSvg,
      },
    }),
    []
  );

  const onClickClose = () => {
    setShow(false);
    //below timeout can be removed if find any regularities this is to get close animation done
    setTimeout(() => {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "DEFAULT",
          msg: "",
          timeout: 3000,
        },
      });
    }, 1000);
  };

  useEffect(() => {
    if (notify.type in color) {
      setShow(true);
      setTimeout(() => {
        onClickClose();
      }, notify.timeout);
    } else {
      setShow(false);
    }
  }, [notify.type, color]);

  if (!(notify.type in color)) {
    return null;
  }

  return (
    <div
      className={`notify-main`}
      style={{ bottom: show ? "5px" : "-75px" }}
      data-testid="test-main"
    >
      <div className={`--msgbox --${notify.type.toLowerCase()}`}>
        <div className="--spanblock">
          <img
            className="--type"
            src={color[notify.type].svg}
            alt={notify.type.toLowerCase()}
          ></img>
          <span className="--msg">{notify.msg}</span>
        </div>
        <img
          className="--close"
          onClick={onClickClose}
          src={color[notify.type].close}
          alt="close"
        ></img>
      </div>
    </div>
  );
};
