import { FC, useContext, useEffect, useState } from "react";
import "./TeamLevel.scss";
import { format_HR_MIN_SEC } from "global/utils/DateUtil";
import {
  Grid,
  GridItem,
  PopUpModel,
  SortIcon,
  ThreedotsIcon,
  Tooltip,
} from "global";
import ProgressBar from "../components/progressBar/ProgressBar";
import { Button, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { PositionState, InvokeType } from "global/components/tooltip/typings";
import ClickAwayListener from "react-click-away-listener";
import CategoryChange from "home/components/categoryManagement/components/CategoryChange";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import CategoryService from "services/CategoryService";
import { useDispatch, useSelector } from "react-redux";
import { roleEnum } from "global/constants/Enums";
import { RootState } from "app/store";

interface IUserAppsProps {
  appsData: any[];
  legends: { label: string; color: string }[];
  categoriesData: any[];
  isLoggedUser: boolean;
}
const baseIndex = 10;
const BASE_CLASS = "teamLevel";
const UserApps: FC<IUserAppsProps> = ({
  appsData,
  legends,
  categoriesData,
  isLoggedUser,
}) => {
  const { themeObjState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [isSortAsc, setSort] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(baseIndex);
  const [isMenuOpen, setIsMenuOpen] = useState<string | undefined>();
  const [requestCatChange, setRequestCatChange] = useState<
    Record<string, any> | undefined
  >();
  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );
  useEffect(() => {
    setIndex(baseIndex);
  }, [appsData]);

  const hideMenu = () => {
    setIsMenuOpen(undefined);
  };

  const getCatDetails = (item: any) => {
    let selApp: any = {};
    categoriesData.some(
      (cat) =>
        item.category === cat.name &&
        cat.subcategories.some((subCat: any) => {
          const apps = subCat.apps.filter(
            (app: any) => app.name === item.appName
          );
          if (apps.length > 0) {
            selApp = { ...apps[0], category: cat, subCategory: subCat };
            return true;
          }
          return false;
        })
    );
    if (selApp?.name) {
      return selApp;
    }
    return { name: item.appName };
  };

  const catOptions = categoriesData
    ?.filter(
      (category: any) =>
        category.id !== requestCatChange?.category?.id &&
        category.id !== "uncategorizedapps" &&
        category.subcategories.length
    )
    .map((cat: any) => ({
      ...cat,
      label: cat.name,
      value: cat.id,
    }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  const changeCategoryfunc = async (data: any) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await CategoryService.changeReq({
        appName: [data?.name],
        fromCategory: data?.category?.id || "uncategorizedapps",
        // fromSubCategory: data.subCategory.id || "",
        toCategory: data?.updatedcategory?.parent?.id || "",
        toSubCategory: data.updatedcategory.id,
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "SUCCESS",
          msg: `Request to change app category has been successfully sent!`,
          timeout: 3000,
        },
      });
    } catch (error: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: error.response?.data?.message || `Request already raised`,
          timeout: 3000,
        },
      });
    }
  };

  const getApps = () =>
    (isSortAsc ? appsData?.slice().reverse() : appsData).slice(0, index);

  return (
    <div className={`${BASE_CLASS}-apps-container`}>
      <Grid
        columns={30}
        className={`${BASE_CLASS}-title-row dds-label `}
        verticalSpacing={"0"}
      >
        <GridItem
          xs={7}
          className={`${BASE_CLASS}-title-row-col`}
          style={{ padding: "10px 1vw" }}
        >
          App name
        </GridItem>
        <GridItem
          xs={3}
          className={`${BASE_CLASS}-title-row-col`}
          style={{ padding: "10px 1vw" }}
        >
          Total hours
          <img
            onClick={() => setSort(!isSortAsc)}
            src={SortIcon}
            alt="header"
            className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
              isSortAsc ? "ASC" : "DESC"
            }`}
          />
        </GridItem>
        <GridItem xs={20} className={`${BASE_CLASS}-title-row-col`} />
        <GridItem xs={3} className={`${BASE_CLASS}-title-row-col`} />
      </Grid>
      {getApps().map(
        (item: any) =>
          item?.duration > 0 && (
            <div key={`${item.appName}`} onMouseLeave={() => hideMenu()}>
              <Grid
                verticalSpacing={"0"}
                columns={30}
                className={`${BASE_CLASS}-body-row dds-body ${BASE_CLASS}-body-row-hover`}
              >
                <GridItem xs={7} className={`${BASE_CLASS}-body-row-col `}>
                  <div className={`${BASE_CLASS}-appname `}>
                    <Tooltip
                      toolTipText={item.appName}
                      tooltipPosition={PositionState.bottom}
                      tooltipInvokeType={InvokeType.hover}
                      tooltipWidth={"max-content"}
                      tooltipMaxWidth={"20vw"}
                    >
                      <div className="ellipsis dds-label">{item.appName}</div>
                    </Tooltip>
                  </div>
                </GridItem>
                <GridItem xs={3} className={`${BASE_CLASS}-body-row-col`}>
                  {format_HR_MIN_SEC(item?.duration)}
                </GridItem>
                <GridItem xs={20} className={`${BASE_CLASS}-body-row-col`}>
                  <ProgressBar
                    isTooltip={false}
                    data={{ [item.category]: item.duration }}
                    totalHrs={appsData[0]?.duration}
                    legends={legends}
                  />
                </GridItem>
                {LoginUserData.role === roleEnum.USER && isLoggedUser && (
                  <GridItem
                    xs={3}
                    className={`${BASE_CLASS}-body-row-col text-center`}
                  >
                    <ThreedotsIcon
                      className={`${BASE_CLASS}-body-row-hover-threedotsIcon`}
                      cursor={"pointer"}
                      fill={isMenuOpen === item.appName ? "black" : ""}
                      onClick={() => {
                        setIsMenuOpen(item.appName);
                      }}
                    />
                  </GridItem>
                )}
                {isMenuOpen && isMenuOpen === item.appName && (
                  <ClickAwayListener onClickAway={hideMenu}>
                    <div className={`${BASE_CLASS}-action-menu`}>
                      <div className={`${BASE_CLASS}-action-options dds-body`}>
                        <span
                          data-testid="delTask"
                          className={`${BASE_CLASS}-action-options-item `}
                          onClick={() => {
                            hideMenu();
                            setRequestCatChange(getCatDetails(item));
                          }}
                        >
                          Request to change app category
                        </span>
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </Grid>
            </div>
          )
      )}
      {index < appsData.length && (
        <div className={`${BASE_CLASS}-addMore`}>
          <Button
            size={Size.m}
            kind={Kind.primary}
            theme={themeObjState}
            width="fixed"
            label="View all"
            onClick={() => {
              // setIndex(index + baseIndex);
              setIndex(appsData.length);
            }}
            isDisabled={appsData.length - index <= 0}
          />
        </div>
      )}

      {requestCatChange && (
        <PopUpModel
          saveTitle={"Send"}
          onClickClose={() => {
            setRequestCatChange(undefined);
          }}
          onSaveClick={() => {
            changeCategoryfunc(requestCatChange);
            setRequestCatChange(undefined);
          }}
          Title={"Move app request"}
          disabledSave={!requestCatChange?.updatedcategory?.id}
        >
          <CategoryChange
            id={requestCatChange?.category?.id || "uncategorizedapps"}
            fromCat={requestCatChange?.category?.name || "Uncategorised apps"}
            fromSubCat={requestCatChange?.subCategory?.name}
            catOptions={catOptions}
            tosubCat={requestCatChange?.updatedcategory}
            changeCat={(item) => {
              setRequestCatChange({
                ...requestCatChange,
                updatedcategory: item,
              });
            }}
          />
        </PopUpModel>
      )}
    </div>
  );
};

export default UserApps;
