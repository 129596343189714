import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import counterReducer from '../features/counterSlice';
import { combineReducers } from "redux";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalReducer from "global/store/reducer";
import homeReducer from "home/store/reducer";
import globalPersistReducer from "global/store/persistReducer";
import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_STORAGE_SECRET_KEY || "";

const encrypt = createTransform(
  (inboundState) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      secretKey
    );

    return cryptedText.toString();
  },
  (outboundState: any) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }
);

//white list the reducers to retain value on refresh
const rootPersistConfig = {
  key: "root",
  storage: storage,
  transforms: [encrypt],
  whitelist: ["globalPersistReducer"],
};

const rootReducer = combineReducers({
  globalReducer,
  homeReducer,
  globalPersistReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
