import { FC, useEffect, useMemo } from "react";
import {
  CloseIconSvg,
  CloseWhiteIconSvg,
  ErrorMsgIcon,
  SuccessIconSvg,
  WarningIcoSvg,
} from "global";
import "./Notify.scss";

interface NotifyInterface {
  notify: {
    type: string;
    timeout: number;
    msg: string;
  };
  undoClick: () => void;
  setSelectedData: (data: any) => void;
}

export const NotifyUndo: FC<NotifyInterface> = ({
  notify,
  undoClick,
  setSelectedData,
}) => {
  const color: any = useMemo(
    () => ({
      SUCCESS: {
        svg: SuccessIconSvg,
        close: CloseWhiteIconSvg,
      },
      ERROR: {
        svg: ErrorMsgIcon,
        close: CloseWhiteIconSvg,
      },
      WARNING: {
        svg: WarningIcoSvg,
        close: CloseIconSvg,
      },
      INFO: {
        svg: SuccessIconSvg,
        close: CloseWhiteIconSvg,
      },
    }),
    []
  );

  const onClickClose = () => {
    setSelectedData(undefined);
  };

  useEffect(() => {
    setTimeout(() => {
      onClickClose();
    }, notify.timeout);
  }, [notify]);

  if (!(notify.type in color)) {
    return null;
  }

  return (
    <div className={`notify-main`}>
      <div className={`--msgbox --${notify.type.toLowerCase()}`}>
        <div className="--spanblock">
          <img
            className="--type"
            src={color[notify.type].svg}
            alt={notify.type.toLowerCase()}
          ></img>
          <span className="--msg">{notify.msg}</span>
        </div>
        <div className="--undo cursor" onClick={undoClick}>
          Undo
        </div>
      </div>
    </div>
  );
};
