import { FC } from "react";
import "./ScreenBlock.css";

interface Props {
  children: React.ReactNode;
}

export const ScreenBlock: FC<Props> = ({ children }) => {
  return (
    <>
      <div className="main-block-screen">{children}</div>
    </>
  );
};
