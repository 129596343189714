import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Size,
  ContextMenuWindow,
  Kind,
  Button,
} from "@usitsdasdesign/dds-react";
import CategoryService from "services/CategoryService";
import {
  PopUpModel,
  DeleteIcon,
  Dropdown,
  ThreedotsIcon,
  Tooltip,
  ChangeCategoryIcon,
  Checkbox,
} from "global";
import { Grid, GridItem, SearchBox, Pagination } from "../../../../../global";
import "./AppsPage.scss";
import AppService from "services/AppService";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import { ThemeContext } from "theme/themeContext";
import { InvokeType, PositionState } from "global/components/tooltip/typings";
import emptySubcategoryIcon from "global/img/emptySubcategory.svg";
import nosubcategoriesfound from "global/img/addsubcategoryImage.svg";
import UncategorizedIcon from "global/img/uncategorizedApp.svg";
import CategoryChange from "../CategoryChange";
type AppRecord = Record<string, any>;

interface AppsPageInterface {
  data: any;
  setRemoveCat: any;
  editCat: any;
  modifySubCat: any;
  addApps: any;
  setCategories: any;
  categories: any;
  filters: ICategoryFilters | any;
  isMaster: boolean;
}
const BASE_CLASS = "appsPage";
export const AppsPage: FC<AppsPageInterface> = ({
  data = { id: "", name: "", apps: [] },
  setRemoveCat,
  editCat,
  addApps,
  modifySubCat,
  setCategories,
  categories,
  filters,
}) => {
  const { themeObjState } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [changeCategory, setChangeCategory] = useState(false);
  const [removeApp, setRemoveApp] = useState(false);
  const [isBulkRemove, setIsBulkRemove] = useState(false);
  const [filterdApps, setFilteredApps] = useState<AppRecord[]>([]);
  const [selectedapps, setSelectedapps] = useState<AppRecord[]>([]);

  const [removeAppData, setRemoveAppData] = useState({
    name: "",
    id: "",
  });
  const [changeCategoryFormData, setChangeCategoryFormData] = useState<{
    currentcategory: string;
    updatedcategory: any;
    apps: any[];
  }>({
    currentcategory: "",
    updatedcategory: {},
    apps: [],
  });

  const [catMenuOpen, setCatMenuOpen] = useState<boolean>();
  const [subCatMenuOpen, setSubCatMenuOpen] = useState<boolean>();
  const elementref = useRef(null);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  useEffect(() => {
    const element: any = elementref.current;
    if (element) {
      const hasScrollBar = element.scrollHeight > element.clientHeight;
      setHasScrollBar(hasScrollBar);
    }
  }, [data?.apps, filterdApps]);

  useEffect(() => {
    if (data.id) {
      setSelectedapps([]);
      setSearchText("");
      searchApps("");
    }
  }, [data, data?.apps]);

  const onEditClick = async (apps: any[]) => {
    setChangeCategory(true);
    setChangeCategoryFormData({
      currentcategory: data.name,
      updatedcategory: {} as any,
      apps,
      // appId: id,
      // appName: appName,
    });
  };
  const onRemoveClick = async (data: any) => {
    setRemoveApp(true);
    setRemoveAppData({
      name: data.name,
      id: data.id,
    });
  };

  const changeCategoryfunc = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      const reqAppIds: any = changeCategoryFormData?.apps.map(
        (item) => item.id
      );

      const filter_request: any = { ...filters };
      if (filter_request?.functionalArea?.length <= 0) {
        filter_request.functionalArea = null;
      }
      if (filter_request?.department?.length <= 0) {
        filter_request.department = null;
      }
      if (filter_request?.subDepartment?.length <= 0) {
        filter_request.subDepartment = null;
      }

      if (
        filters.functionalArea === "" &&
        filters.department === "" &&
        filters.subDepartment === ""
      ) {
        const reqAppIds = changeCategoryFormData.apps.map((item) => item.id);
        await CategoryService.update(
          changeCategoryFormData.updatedcategory.id,
          {
            apps: reqAppIds,
          }
        );
      } else {
        const apps = changeCategoryFormData.apps.map((item) => item.name);
        await CategoryService.changeReq({
          ...filter_request,
          appName: apps,
          fromCategory: data?.parent?.id || "uncategorizedapps",
          toCategory: changeCategoryFormData.updatedcategory?.parent?.id,
          toSubCategory: changeCategoryFormData.updatedcategory?.id,
        });
      }
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "SUCCESS",
          msg: `Selected URL/app have been successfully moved to ${
            changeCategoryFormData.updatedcategory?.parent?.name ||
            changeCategoryFormData.updatedcategory?.label
          } category.`,
          timeout: 3000,
        },
      });

      setCategories((prevCat: any) => {
        return prevCat.map((cat: any) => {
          if (cat.id === "uncategorizedapps") {
            cat.apps = cat.apps.filter(
              (item: any) => !reqAppIds.includes(item.id)
            );
          } else {
            cat.subcategories = cat?.subcategories?.map((subCat: any) => {
              if (subCat.id === data.id) {
                subCat.apps = subCat.apps.filter(
                  (item: any) => !reqAppIds.includes(item.id)
                );
              } else if (
                subCat.id === changeCategoryFormData.updatedcategory.id
              ) {
                subCat.apps = [...subCat.apps, ...changeCategoryFormData.apps];
                // res.data.data.apps;
              }
              return subCat;
            });
          }
          return cat;
        });
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong!`,
          timeout: 3000,
        },
      });
    }
  };

  const bulkRemoveAppfunc = async () => {
    try {
      if (selectedapps.length > 0) {
        const reqAppIds = selectedapps.map((obj: any) => obj["id"]);
        dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
        await CategoryService.update(
          data.id,
          {
            name: data.name,
            apps: reqAppIds,
          },
          {
            bulkdelete: true,
          }
        );
        dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "SUCCESS",
            msg: "Selected URLs/apps have been successfully removed.",
            timeout: 3000,
          },
        });
        setCategories((prevCat: any) => {
          return prevCat.map((cat: any) => {
            if (cat.id === "uncategorizedapps") {
              cat.apps = [...cat.apps, ...selectedapps];
            } else if (cat.id === data.parent.id) {
              cat.subcategories.map((subCat: any) => {
                if (subCat.id === data.id) {
                  subCat.apps = subCat.apps.filter(
                    (item: any) => !selectedapps.includes(item)
                  );
                }
                return subCat;
              });
            }
            return cat;
          });
        });
        setSelectedapps([]);
      }
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `You are unauthorized to perform this action`,
          timeout: 3000,
        },
      });
    }
  };

  const removeAppfunc = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      const res = await AppService.update(removeAppData.id, {
        category: null,
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "SUCCESS",
          msg: "Selected URL/app have been successfully removed.",
          timeout: 3000,
        },
      });
      setCategories((prevCat: any) => {
        return prevCat.map((cat: any) => {
          if (cat.id === "uncategorizedapps") {
            cat.apps = [...cat.apps, res.data.data];
          } else if (cat.id === data.parent.id) {
            cat.subcategories.map((subCat: any) => {
              if (subCat.id === data.id) {
                subCat.apps = subCat.apps.filter(
                  (app: any) => app.id !== removeAppData.id
                );
              }
              return subCat;
            });
          }
          return cat;
        });
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `You are unauthorized to perform this action`,
          timeout: 3000,
        },
      });
    }
  };

  const searchApps = (searchVal: string = searchText) => {
    try {
      let arrFiltered = [];
      if (searchVal.length > 0) {
        arrFiltered = data.apps.filter((app: any) =>
          (app.name + app.title).toLowerCase().includes(searchVal.toLowerCase())
        );
      } else {
        arrFiltered = data.apps;
      }
      setCurrentPage(1);
      setFilteredApps(
        arrFiltered.sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
    } catch (err) {}
  };

  const catOptions = categories
    ?.filter(
      (category: any) =>
        category.id !== data?.parent?.id &&
        category.id !== "uncategorizedapps" &&
        category.subcategories.length
    )
    .map((cat: any) => ({
      ...cat,
      label: cat.name,
      value: cat.id,
    }));
  // .sort((a: any, b: any) => a.label.localeCompare(b.label));

  return (
    <>
      {data && (
        <div className="--apps-mainbox-content">
          <div className="category-titlebar" style={{ margin: "5px 0" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "larger",
                padding: "0 15px 0 5px",
              }}
            >
              {data.parent === null
                ? data.name
                : data?.parent?.name || data.name}
            </div>
            {data?.id !== "uncategorizedapps" && (
              <ThreedotsIcon
                className="action-item"
                stroke={"black"}
                cursor={"pointer"}
                onClick={() => {
                  setCatMenuOpen(!catMenuOpen);
                }}
              />
            )}
            {catMenuOpen && (
              <ClickAwayListener onClickAway={() => setCatMenuOpen(false)}>
                <div className={`${BASE_CLASS}-popupMenu-container`}>
                  <div className={`${BASE_CLASS}-popupMenu-container-box`}>
                    <div
                      className={`${BASE_CLASS}-popupMenu-container-box-body dds-body`}
                    >
                      <ContextMenuWindow
                        ariaLabel="Context Menu"
                        controlType="none"
                        maxHeight={0}
                        menuItems={[
                          {
                            type: "menuItem",
                            value: {
                              isActive: false,
                              isDisabled: false,
                              label: "Edit category name",
                            },
                          },
                          {
                            type: "menuItem",
                            value: {
                              isActive: false,
                              isDisabled: false,
                              label: "Remove category",
                            },
                          },
                          {
                            type: "menuItem",
                            value: {
                              isActive: false,
                              isDisabled: false,
                              label: "Add subcategory",
                            },
                          },
                        ]}
                        selectedItem={(e) => {
                          if (e.value.label === "Edit category name") {
                            editCat(data?.parent || data);
                          } else if (e.value.label === "Remove category") {
                            setRemoveCat(data.parent || data);
                          } else {
                            modifySubCat(undefined);
                          }
                          setCatMenuOpen(false);
                        }}
                        size={Size.s}
                        width={400}
                      />
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="divider" style={{ width: "100%" }} />

          {!data.parent && data?.id !== "uncategorizedapps" ? (
            <>
              <div className="--apps-mainbox-content emptyScreen">
                <div className="add-sub-container">
                  <img src={nosubcategoriesfound} alt="Add categories" />
                  <h3>No subcategories found.</h3>
                  <p>Create sub category to add URLs/apps.</p>
                  <Button
                    size={Size.m}
                    kind={Kind.primaryLoud}
                    theme={themeObjState}
                    label="Add sub category"
                    onClick={() => {
                      modifySubCat(undefined);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="--apps-mainbox-content"
                style={{ padding: "4vh 2vw  0vh 2vw" }}
              >
                {data?.id !== "uncategorizedapps" && (
                  <>
                    <div
                      className="category-titlebar"
                      style={{ padding: "0", margin: "0 0 20px 0" }}
                    >
                      <div style={{ fontWeight: "500" }}>{data.name}</div>
                      <ThreedotsIcon
                        className="action-item"
                        stroke={"black"}
                        cursor={"pointer"}
                        onClick={() => {
                          setSubCatMenuOpen(!subCatMenuOpen);
                        }}
                      />
                      {subCatMenuOpen && (
                        <ClickAwayListener
                          onClickAway={() => setSubCatMenuOpen(false)}
                        >
                          <div className={`${BASE_CLASS}-popupMenu-container`}>
                            <div
                              className={`${BASE_CLASS}-popupMenu-container-box`}
                            >
                              <div
                                className={`${BASE_CLASS}-popupMenu-container-box-body dds-body`}
                              >
                                <ContextMenuWindow
                                  ariaLabel="Context Menu"
                                  controlType="none"
                                  maxHeight={0}
                                  menuItems={[
                                    {
                                      type: "menuItem",
                                      value: {
                                        isActive: false,
                                        isDisabled: false,
                                        label: "Edit subcategory name",
                                      },
                                    },
                                    {
                                      type: "menuItem",
                                      value: {
                                        isActive: false,
                                        isDisabled: false,
                                        label: "Remove subcategory",
                                      },
                                    },
                                  ]}
                                  selectedItem={(e) => {
                                    if (
                                      e.value.label === "Edit subcategory name"
                                    ) {
                                      modifySubCat(data);
                                    } else {
                                      setRemoveCat(data);
                                    }
                                    setSubCatMenuOpen(false);
                                  }}
                                  size={Size.s}
                                  width={400}
                                />
                              </div>
                            </div>
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  </>
                )}
                {data?.apps?.length === 0 ? (
                  data.id !== "uncategorizedapps" ? (
                    <div className="add-apps-container emptyScreen">
                      <img src={emptySubcategoryIcon} alt="Add apps" />
                      <h3>No URLs/apps found.</h3>
                      <p>Add apps to the subcategory to proceed.</p>
                      <Button
                        size={Size.m}
                        kind={Kind.primaryLoud}
                        theme={themeObjState}
                        label="Add apps"
                        onClick={async () => {
                          // onAddAppsClick(data.id);
                          addApps(data);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="uncategorized-apps emptyScreen">
                      <img src={UncategorizedIcon} alt="Add apps" />
                      <h3>No URLs/apps found.</h3>
                    </div>
                  )
                ) : (
                  <>
                    <div className="--processes-top-action-box">
                      <div
                        className="--processes-top-action-box"
                        style={{ gap: "20px" }}
                      >
                        <div className="actions">
                          <Checkbox
                            disabled={filterdApps.length === 0}
                            value={
                              filterdApps.length > 0 &&
                              !filterdApps.some(
                                (item) =>
                                  !selectedapps
                                    .map((val) => val.id)
                                    .includes(item.id)
                              )
                            }
                            onChange={(e) => {
                              const tempApps = selectedapps.filter(
                                (item) =>
                                  !filterdApps
                                    .map((val) => val.id)
                                    .includes(item.id)
                              );
                              if (e.target.checked) {
                                setSelectedapps(
                                  [...tempApps, ...filterdApps].sort(
                                    (a: any, b: any) =>
                                      a.name.localeCompare(b.name)
                                  )
                                );
                              } else {
                                setSelectedapps(tempApps);
                              }
                            }}
                          />
                          <div className="vertical-divider" />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Tooltip
                              toolTipText={"Switch categories"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <ChangeCategoryIcon
                                className={`action-item ${
                                  selectedapps.length === 0 && "disabled"
                                }`}
                                stroke={"black"}
                                cursor={"pointer"}
                                onClick={async () => {
                                  onEditClick(selectedapps);
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              toolTipText={"Remove applications"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <DeleteIcon
                                className={`action-item ${
                                  (data?.id === "uncategorizedapps" ||
                                    selectedapps.length === 0) &&
                                  "disabled"
                                }`}
                                stroke={"black"}
                                cursor={"pointer"}
                                onClick={async () => {
                                  setRemoveApp(true);
                                  setIsBulkRemove(true);
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <SearchBox
                          className="process-searchbox"
                          placeholder="Search application"
                          style={{ width: "350px" }}
                          value={searchText}
                          onChange={(val: string) => {
                            searchApps(val);
                            setSearchText(val);
                          }}
                          onClear={() => searchApps("")}
                          onClickEnter={() => searchApps()}
                        />
                        {data?.id !== "uncategorizedapps" && (
                          <Button
                            size={Size.m}
                            className="add-apps-button"
                            kind={Kind.primaryLoud}
                            theme={themeObjState}
                            label="Add apps"
                            onClick={() => {
                              // onAddAppsClick(data.id);
                              addApps(data);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {Object.keys(data).length !== 0 ? (
                      filterdApps.length === 0 || data?.apps.length === 0 ? (
                        <div className="--main-content apps-body noresult emptyScreen">
                          <div>
                            {data?.apps?.length === 0
                              ? "No apps exist in the selected category"
                              : data?.apps.length !== 0
                              ? "No results found"
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <div className="--main-content apps-body">
                          <Grid
                            className="--processrow-title dds-label-semibold"
                            style={hasScrollBar ? { paddingRight: "10px" } : {}}
                            columns={20}
                          >
                            <GridItem xs={1}></GridItem>
                            <GridItem xs={1.75}>S.No.</GridItem>
                            <GridItem xs={6.4}>URL/application</GridItem>
                            <GridItem xs={5}>Action</GridItem>
                          </Grid>
                          <div
                            className="--processrow-container dds-body"
                            ref={elementref}
                          >
                            {filterdApps
                              .slice(
                                (currentPage - 1) * itemsPerPage,
                                currentPage * itemsPerPage
                              )
                              .map((e: any, i: any) => {
                                return (
                                  <Grid
                                    key={e.id}
                                    className="--processrow dds-body"
                                    columns={20}
                                  >
                                    <GridItem xs={2}>
                                      <Checkbox
                                        value={
                                          selectedapps.filter(
                                            (app: any) => app.id === e.id
                                          ).length > 0
                                        }
                                        onChange={() => {
                                          if (
                                            selectedapps.filter(
                                              (app: any) => app.id === e.id
                                            ).length > 0
                                          ) {
                                            setSelectedapps(
                                              selectedapps.filter(
                                                (app: any) => app.id !== e.id
                                              )
                                            );
                                          } else {
                                            setSelectedapps((prev) => [
                                              ...prev,
                                              e,
                                            ]);
                                          }
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={3.7}>
                                      {(currentPage - 1) * itemsPerPage +
                                        (i + 1)}
                                    </GridItem>
                                    <GridItem
                                      xs={12}
                                      style={{ paddingRight: "35px" }}
                                    >
                                      {e.name}
                                    </GridItem>
                                    <GridItem
                                      xs={5}
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <Tooltip
                                        toolTipText={"Switch category"}
                                        tooltipPosition={PositionState.bottom}
                                        tooltipInvokeType={InvokeType.hover}
                                        tooltipWidth={"max-content"}
                                      >
                                        <ChangeCategoryIcon
                                          className="action-item"
                                          stroke={"black"}
                                          cursor={"pointer"}
                                          onClick={async () => {
                                            onEditClick([e]);
                                          }}
                                        />
                                      </Tooltip>
                                      {data?.id !== "uncategorizedapps" && (
                                        <Tooltip
                                          toolTipText={"Remove application"}
                                          tooltipPosition={PositionState.bottom}
                                          tooltipInvokeType={InvokeType.hover}
                                          tooltipWidth={"max-content"}
                                        >
                                          <DeleteIcon
                                            className="action-item"
                                            stroke={"black"}
                                            cursor={"pointer"}
                                            onClick={async () => {
                                              onRemoveClick(e);
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </GridItem>
                                  </Grid>
                                );
                              })}
                          </div>
                          <div className={`${BASE_CLASS}-pagination`}>
                            <Pagination
                              currentPage={currentPage || 1}
                              lastPage={Math.ceil(
                                filterdApps?.length / itemsPerPage
                              )}
                              setCurrentPage={(item) => {
                                setCurrentPage(item);
                              }}
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {removeApp && (
            <PopUpModel
              Title="Remove application"
              onClickClose={() => {
                setRemoveApp(false);
                setIsBulkRemove(false);
              }}
              onSaveClick={async () => {
                if (!Object.keys(filters).some((key) => filters[key] !== "")) {
                  isBulkRemove ? bulkRemoveAppfunc() : removeAppfunc();
                } else {
                  dispatch({
                    type: CALL_NOTIFY,
                    payload: {
                      type: "ERROR",
                      msg: `You are unauthorized to perform this action`,
                      timeout: 3000,
                    },
                  });
                }
                setRemoveApp(false);
                setIsBulkRemove(false);
              }}
              saveTitle="Remove"
              closeTitle="Cancel"
              saveButtonClass="deleteColor"
            >
              <p style={{ fontSize: "14px" }}>
                {`All the apps will be moved to uncategorised section. Are you sure you want to remove these apps?`}
              </p>
            </PopUpModel>
          )}
          {changeCategory &&
            (changeCategoryFormData.currentcategory !== undefined ? (
              <PopUpModel
                saveTitle={data?.id !== "uncategorizedapps" ? "Change" : "Assign"}
                onClickClose={() => {
                  setChangeCategory(false);
                }}
                onSaveClick={() => {
                  changeCategoryfunc();
                  setChangeCategory(false);
                }}
                Title={
                  data?.id !== "uncategorizedapps"
                    ? "Change app category"
                    : "Assign category"
                }
                disabledSave={
                  Object.keys(changeCategoryFormData.updatedcategory).length ===
                  0
                }
                popupModelMainStyle={{ width: "40%" }}
              >
                <CategoryChange
                  isInfo={Object.keys(filters).some(
                    (key) => filters[key] !== ""
                  )}
                  id={data?.id}
                  fromCat={data?.parent?.name}
                  fromSubCat={changeCategoryFormData.currentcategory}
                  catOptions={catOptions}
                  tosubCat={changeCategoryFormData?.updatedcategory}
                  changeCat={(item) => {
                    setChangeCategoryFormData({
                      ...changeCategoryFormData,
                      updatedcategory: item,
                    });
                  }}
                />
              </PopUpModel>
            ) : (
              <PopUpModel
                saveTitle={"Add"}
                onClickClose={() => {
                  setChangeCategory(false);
                }}
                onSaveClick={() => {
                  changeCategoryfunc();
                  setChangeCategory(false);
                }}
                Title={""}
                disabledSave={
                  Object.keys(changeCategoryFormData.updatedcategory).length ===
                  0
                }
              >
                <div className="--formItem">
                  <div className="inputbox-container width-100">
                    <Dropdown
                      className="width-100"
                      defaultValue={""}
                      labelText={"Add to category"}
                      isRequired={true}
                      addLabel={true}
                      onchange={(item) => {
                        setChangeCategoryFormData({
                          ...changeCategoryFormData,
                          updatedcategory: item,
                        });
                      }}
                      placeholder={"Select category"}
                      options={catOptions}
                      isSearchable={true}
                      controlShouldRenderValue={false}
                      isClearable={false}
                      dropdownIndicatorStyle={{ display: "none" }}
                      containerStyle={{ display: "flex", gap: "2px" }}
                      isSearchBox={true}
                      //customFilter={customFilter}
                    />
                  </div>
                </div>
              </PopUpModel>
            ))}
        </div>
      )}
    </>
  );
};

export default AppsPage;
