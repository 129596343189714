import { Dropdown, Tooltip, filterSvg, sliderArrow } from "global";
import { FC, useContext, useEffect, useState } from "react";
import "./CategoryFilter.scss";
import { Button, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { PositionState, InvokeType } from "global/components/tooltip/typings";
import { getAllusers } from "services/orgLevelService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { SHOWSCREENBLOCKMSG } from "global/store/action";
import { roleEnum } from "global/constants/Enums";
interface IOption {
  label: string;
  value: string;
}
interface IFilterProps {
  updateFilters: (data: any) => void;
  initialFilters: ICategoryFilters;
}

const BASE_CLASS = "catFilters";
const Filter: FC<IFilterProps> = ({ updateFilters, initialFilters }) => {
  const { themeObjState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [functionalAreas, setFunctionalAreas] = useState<IOption[]>([]);
  const [departments, setDepartments] = useState<IOption[]>([]);
  const [subDepartments, setSubDepartments] = useState<IOption[]>([]);
  const [allData, setAllData] = useState<Record<string, any>>([]);
  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );
  const getInitFilters = (): ICategoryFilters => ({
    functionalArea: "",
    department: "",
    subDepartment: "",
  });

  const [filters, setFilters] = useState<ICategoryFilters | any>();

  useEffect(() => {
    if (LoginUserData.entitlements) {
      getData();
    }
  }, [LoginUserData]);

  useEffect(() => {
    if (allData.length) {
      const tempFilters = getInitFilters();
      setFilters(tempFilters);
      if (LoginUserData.role === roleEnum.MASTER) {
        updateFilters(tempFilters);
      } else {
        dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      }
    }
  }, [allData]);

  const getData = async () => {
    const params = {
      selection: "functionalArea,department,subDepartment",
    };
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await getAllusers(params).then((res: any) => {
        const resData = res.data.data.record;
        setAllData(resData);
        getFunctionalAreas(resData);
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      console.log(error);
    }
  };

  const getFunctionalAreas = (resData: any) => {
    setFunctionalAreas(
      (
        [
          ...new Set(
            LoginUserData.entitlements?.levels.some(
              (item) => item.functionalArea === "*"
            ) || LoginUserData.role === roleEnum.MASTER
              ? resData.map((item: IUser | any) => item.functionalArea)
              : LoginUserData.entitlements?.levels.map(
                  (item) => item.functionalArea
                )
          ),
        ] as string[]
      )
        .sort()
        .map((item) => ({ label: item, value: item }))
    );
  };
  useEffect(() => {
    getDepts();
  }, [filters?.functionalArea]);

  const getDepts = () => {
    const tempData = allData.filter(
      (item: IUser | any) =>
        item.functionalArea === filters?.functionalArea && item.department
    );
    setSubDepartments([]);
    if (tempData.length === 0) {
      setDepartments([]);
      return;
    }

    const tempEn = LoginUserData.entitlements?.levels
      .filter((item) => item.functionalArea === filters?.functionalArea)
      .map((item: { department: string }) => item.department) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.department
    ) as string[];

    setDepartments(
      (
        [
          ...new Set(
            tempEn?.some((item: string) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
              ? tempSubDepts
              : tempEn.filter((item) => tempSubDepts.includes(item))
          ),
        ] as string[]
      )
        .sort()
        .map((item) => ({ label: item, value: item }))
    );
  };

  useEffect(() => {
    getSubDepts();
  }, [filters?.department]);

  const getSubDepts = () => {
    const tempData = allData.filter(
      (item: IUser | any) =>
        item.functionalArea === filters?.functionalArea &&
        item.department === filters?.department &&
        item.subDepartment
    );
    if (tempData.length === 0) {
      setSubDepartments([]);
      return;
    }
    const tempEn = LoginUserData.entitlements?.levels
      .filter(
        (item) =>
          item.functionalArea === filters?.functionalArea &&
          item.department === filters?.department
      )
      .map((item: { subDepartment: string }) => item.subDepartment) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.subDepartment
    ) as string[];

    setSubDepartments(
      (
        [
          ...new Set(
            tempEn?.some((item) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
              ? tempSubDepts
              : tempEn.filter((item) => tempSubDepts.includes(item))
          ),
        ] as string[]
      )
        .sort()
        .map((item) => ({ label: item, value: item }))
    );
  };

  const isClearDIsabled = () =>
    !(
      JSON.stringify(initialFilters) !== JSON.stringify(filters) ||
      JSON.stringify(getInitFilters()) !== JSON.stringify(filters)
    );

  const checkApplyDisabled = (): boolean => {
    if (LoginUserData.role === roleEnum.ENTITLED) {
      return !LoginUserData.entitlements?.levels.some(
        (item) =>
          item.functionalArea === filters?.functionalArea &&
          (item.department === "" || item.department === filters?.department) &&
          (item.subDepartment === "" ||
            item.subDepartment === filters?.subDepartment)
      );
    }
    return false;
  };

  return (
    <div className={BASE_CLASS}>
      <Tooltip
        toolTipText={isExpanded ? "Collapse" : "Expand"}
        tooltipPosition={PositionState.bottom}
        tooltipInvokeType={InvokeType.hover}
        tooltipWidth={"max-content"}
      >
        <img
          src={sliderArrow}
          alt="arrow"
          style={!isExpanded ? { transform: "rotate(180deg)" } : {}}
          onClick={() => setIsExpanded(!isExpanded)}
          className="cursor"
        />
      </Tooltip>
      <div className={`${BASE_CLASS}-slider`}>
        {isExpanded ? (
          <div className={`${BASE_CLASS}-slider-content`}>
            <div className="dds-h5">Filter</div>
            <div className={`${BASE_CLASS}-dropdowns`}>
              <div>
                <Dropdown
                  className="width-100"
                  defaultValue={{
                    label: filters?.functionalArea,
                    value: filters?.functionalArea,
                  }}
                  labelText={"Function"}
                  addLabel={true}
                  showValue={true}
                  onchange={(item: any) => {
                    setFilters({
                      functionalArea: item.value,
                      department: "",
                      subDepartment: "",
                    });
                  }}
                  placeholder={"Select"}
                  // disabled={
                  //   LoginUserData.entitlements.functionalArea !== "*" &&
                  //   LoginUserData.entitlements.functionalArea !== ""
                  // }
                  options={functionalAreas}
                  isSearchable={true}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  dropdownIndicatorStyle={{ display: "none" }}
                  containerStyle={{ display: "flex", gap: "2px" }}
                  isSearchBox={true}
                />
              </div>
              <div>
                <Dropdown
                  className="width-100"
                  defaultValue={{
                    label: filters?.department,
                    value: filters?.department,
                  }}
                  labelText={"Department"}
                  addLabel={true}
                  showValue={true}
                  onchange={(item: any) => {
                    setFilters((prevFilters: any) => ({
                      ...prevFilters,
                      department: item.value,
                      subDepartment: "",
                    }));
                    // getSubDepts(filters?.functionalArea, item.value);
                  }}
                  placeholder={"Select"}
                  // disabled={
                  //   LoginUserData.entitlements.department !== "*" &&
                  //   LoginUserData.entitlements.department !== ""
                  // }
                  options={departments}
                  isSearchable={true}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  dropdownIndicatorStyle={{ display: "none" }}
                  containerStyle={{ display: "flex", gap: "2px" }}
                  isSearchBox={true}
                />
              </div>
              <div>
                <Dropdown
                  className="width-100"
                  defaultValue={{
                    label: filters?.subDepartment,
                    value: filters?.subDepartment,
                  }}
                  labelText={"Sub - department"}
                  addLabel={true}
                  showValue={true}
                  onchange={(item: any) => {
                    setFilters((prevFilters: any) => ({
                      ...prevFilters,
                      subDepartment: item.value,
                    }));
                  }}
                  placeholder={"Select"}
                  // disabled={
                  //   LoginUserData.entitlements.subDepartment !== "*" &&
                  //   LoginUserData.entitlements.subDepartment !== ""
                  // }
                  options={subDepartments}
                  isSearchable={true}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  dropdownIndicatorStyle={{ display: "none" }}
                  containerStyle={{ display: "flex", gap: "2px" }}
                  isSearchBox={true}
                />
              </div>
            </div>
            <div className={`${BASE_CLASS}-footer`}>
              <Button
                size={Size.m}
                kind={Kind.primary}
                theme={themeObjState}
                width="fixed"
                label="Clear"
                isDisabled={isClearDIsabled()}
                onClick={() => {
                  if (filters?.functionalArea) {
                    const tempFilters = getInitFilters();
                    setFilters(tempFilters);
                    updateFilters(tempFilters);

                    getDepts();
                    getSubDepts();
                  }
                }}
              />
              <Button
                size={Size.m}
                kind={Kind.primaryLoud}
                theme={themeObjState}
                width="fixed"
                label="Apply"
                isDisabled={
                  JSON.stringify(initialFilters) === JSON.stringify(filters) ||
                  checkApplyDisabled()
                }
                onClick={() => {
                  updateFilters(filters);
                }}
              />
            </div>
          </div>
        ) : (
          <img
            src={filterSvg}
            alt="filter"
            style={{ padding: "1vh", height: "2vh" }}
          />
        )}
      </div>
    </div>
  );
};

export default Filter;
