import React, { FC } from "react";
import "./Grid.css";

const GridContext = React.createContext(12);

interface GridInterface {
  className?: string;
  columns?: number;
  spacing?: string | number;
  verticalSpacing?: string | number;
  children?: any;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  title?: string | undefined;
}

export const Grid: FC<GridInterface> = ({
  className = "",
  columns = 12,
  spacing = undefined,
  verticalSpacing = undefined,
  children = [],
  style = {},
  onClick = undefined,
  title = undefined,
}) => {
  return (
    <GridContext.Provider value={columns}>
      <div
        title={title ?? undefined}
        className={`main-grid ${className}`}
        onClick={onClick}
        style={{
          rowGap: spacing ? spacing : "10px",
          columnGap: verticalSpacing ? verticalSpacing : "10px",
          ...style,
        }}
      >
        {children}
      </div>
    </GridContext.Provider>
  );
};

interface GridItemInterface {
  xs?: boolean | number;
  className?: string;
  children?: any;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const GridItem: FC<GridItemInterface> = ({
  className = "",
  xs = undefined,
  children = null,
  style = {},
  onClick = undefined,
}) => {
  const columnsContext = React.useContext(GridContext);
  if (xs === true) xs = 1;
  if (xs) style.flexBasis = `${(xs * 100) / columnsContext}%`;
  if (xs) style.maxWidth = `${(xs * 100) / columnsContext}%`;
  return (
    <div
      className={`main-grid-item ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
