import { Header, Color } from "@usitsdasdesign/dds-react";
import "./HeaderComponent.css";
// import AuthService from "services/AuthService";
// import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ThemeContext } from "theme/themeContext";
import { roleEnum } from "global/constants/Enums";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

interface HeaderProps {
  setPage?: any;
  page: any;
  isHome?: boolean;
}

const HeaderComponent: React.FC<HeaderProps> = ({
  setPage,
  page,
  isHome = true,
}) => {
  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );
  const userdata = JSON.parse(window.localStorage["kcUserData"]);
  const role = LoginUserData?.role || "";
  const { themeObjState } = useContext(ThemeContext);
  useEffect(() => {
    const listener = () => {
      setPage("LANDING");
    };
    document
      ?.querySelector(".DDSHeader__projectName")
      ?.addEventListener("click", listener);
  }, []);

  const isFunctionalAreaAccess: boolean =
    LoginUserData.entitlements?.levels.some(
      (item) =>
        LoginUserData.role === roleEnum.MASTER ||
        (LoginUserData.role === roleEnum.ENTITLED &&
          item.department === "" &&
          item.subDepartment === ""
        )
    ) || false;

  const tabsData = {
    DASHBOARD: {
      title: "Dashboard",
      onClick: () => {
        setPage("LANDING");
      },
      isActive: page === "LANDING",
      ariaLabel: "Dashboard",
    },
    CATEGORYMANAGEMENT: {
      title: "Category management",
      onClick: () => {
        setPage("CATEGORYMANAGEMENT");
      },
      isActive: page === "CATEGORYMANAGEMENT",
      ariaLabel: "Category management",
    },
    APPROVALSANDREQUESTS: {
      title: "Approvals and requests",
      onClick: () => {
        setPage("APPROVALSANDREQUESTS");
      },
      isActive: page === "APPROVALSANDREQUESTS",
      ariaLabel: "Approvals and requests",
    },
    POWERBIDASHBOARD: {
      title: "Power BI dashboard",
      onClick: () => {
        setPage("POWERBIDASHBOARD");
      },
      isActive: page === "POWERBIDASHBOARD",
      ariaLabel: "Power BI dashboard",
    },
    REPORTS: {
      title: "Reports",
      onClick: () => {
        setPage("REPORTS");
      },
      isActive: page === "REPORTS",
      ariaLabel: "Reports",
    }
  };

  return (
    <div className="Sandbox-container">
      <Header
        theme={themeObjState}
        color={Color.green}
        customDarkLogo=""
        customLightLogo=""
        isResponsive
        navigationMenu={
          isHome
            ? role === roleEnum.MASTER || role === roleEnum.ENTITLED
              ? isFunctionalAreaAccess
                ? [
                  tabsData.DASHBOARD, tabsData.CATEGORYMANAGEMENT, tabsData.POWERBIDASHBOARD, tabsData.REPORTS,
                ] : [
                  tabsData.DASHBOARD, tabsData.CATEGORYMANAGEMENT, tabsData.POWERBIDASHBOARD,
                ]
              : [
                tabsData.DASHBOARD,
              ]
            : []
        }
        projectName="Activity Track"
        // searchCallback={function noRefCheck() {}}
        userProfileData={{
          className: "",
          navigation: [],
          user: {
            initials: userdata.family_name[0] + userdata.given_name[0],
            photoURL: "",
            userInfo: role.toLowerCase(),
            username: userdata.name.split(",").reverse().join(" "),
          },
        }}
      />
    </div>
  );
};

export default HeaderComponent;
