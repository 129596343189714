import { FC } from "react";
import "./AccessDenied.scss";
import { accessDeniedIcon } from "global";
import HeaderComponent from "../headerComponent/HeaderComponent";

const AccessDenied: FC = () => {
  return (
    <div className="acessDenied">
      <HeaderComponent page={""} isHome={false} />
      <div className="acessDenied-img">
        <img src={accessDeniedIcon} alt="accessDenied" />
      </div>
    </div>
  );
};

export default AccessDenied;
