import { ACTIVE_TAB } from "./action";

export interface StateInterface {
  activeTab: string;
}

const initialState: StateInterface = {
  activeTab: "LANDING",
};

type actionType = {
  type: string;
  payload: any;
};

export default function globalPersistReducer(
  state = initialState,
  action: actionType
) {
  switch (action.type) {
    case ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
}
