import { FC, Fragment, useContext, useEffect, useState } from "react";
import "./OrgLevel.scss";
import Analytics from "./analytics/Analytics";
import { getAllusers } from "../../../../services/orgLevelService";
import DonutChart from "./donutChart/DonutChart";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch } from "react-redux";
import { getStartOfDay, getEndOfDay } from "global/utils/DateUtil";
import HoursChart from "./hoursChart/HoursChart";
import CategoryService from "services/CategoryService";
import COLORSlIST, { CAT_COLORS_LIST } from "global/constants/Colors";
import { DateRangePicker, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { BackIcon, NotifyToast } from "global";

const BASE_CLASS = "orgLevel";
const locationKey = "officeLocation";
const departmentKey = "department";
const subDepartmentKey = "subDepartment";
const functionalAreaKey = "functionalArea";

const baseDate = new Date(new Date().setDate(new Date().getDate() - 1));
const OrgLevel: FC = () => {
  const { themeObjState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<string[]>([]); // list of all categories
  const [analyticsData, setAnalytics] = useState<any[]>([]); // list of all categories
  const [activeCategories, setActiveCategories] = useState<string[]>([]); // list of all active categories
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: baseDate,
    endDate: baseDate,
  }); // date range for filtering data

  const [masterData, setMasterData] = useState<IUser[]>([]); // master data
  const [pieChartData, setPieChartData] = useState<IUser[]>([]); // location and department master data
  const [masterUsersData, setMasterUsersData] = useState<any[]>([]); // list of all users
  const [usersData, setUsersData] = useState<any[]>([]); // list of all users filtered by category

  const [colorList, setColorList] = useState<Record<string, string>>({});

  const [activeGraphKey, setActiveGraphKey] =
    useState<string>(functionalAreaKey);

  const [filters, setFilters] = useState<
    | {
        [functionalAreaKey]?: Record<string, any>;
        [departmentKey]?: Record<string, any>;
        [subDepartmentKey]?: Record<string, any>;
        [locationKey]?: Record<string, any>;
      }
    | any
  >({});

  useEffect(() => {
    getCatData();
  }, []);

  const getCatData = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      const params = {
        selection: "name",
      };
      const resData: any = await CategoryService.getAll(params);
      setCategories([...resData.data.data.map((item: any) => item.name)]);
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Failed to load`,
          timeout: 3000,
        },
      });
    }
  };

  useEffect(() => {
    if (categories.length) {
      getMasterDataPieCharts();
      if (filters?.[departmentKey]?.data?.label) {
        getUsers();
      }
    }
  }, [categories, dateRange.startDate, dateRange.endDate]);

  const getMasterDataPieCharts = async () => {
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
    try {
      const params = {
        startDate: getStartOfDay(dateRange.startDate),
        endDate: getEndOfDay(dateRange.endDate),
      };
      await getAllusers(params).then((res: any) => {
        const resData = res.data?.data;
        generateColorList(resData);
        const tempHrsData = resData?.map((item: any) => item.timespent);
        setAnalytics(
          categories.map((cat) => ({
            label: cat,
            value: tempHrsData.reduce(
              (prevVal: any, currVal: any) => prevVal + currVal[cat],
              0
            ),
          }))
        );
        setMasterData(resData);
        setPieChartData(getHrsData(resData));
      });
    } catch (error) {}
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
  };

  function generateColorList(resData: IUser[]) {
    let listData = {};
    const uniqueFunArea = [
      ...new Set(resData.map((item: IUser | any) => item[functionalAreaKey])),
    ];
    const uniqueDept = [
      ...new Set(resData.map((item: IUser | any) => item[departmentKey])),
    ];
    const uniqueSubDept = [
      ...new Set(resData.map((item: IUser | any) => item[subDepartmentKey])),
    ];

    uniqueFunArea.forEach((val: any, i: number) => {
      listData = { ...listData, [val]: COLORSlIST[i % COLORSlIST.length] };
    });
    uniqueDept.forEach((val: any, i: number) => {
      listData = { ...listData, [val]: COLORSlIST[i % COLORSlIST.length] };
    });
    uniqueSubDept.forEach((val: any, i: number) => {
      listData = { ...listData, [val]: COLORSlIST[i % COLORSlIST.length] };
    });
    [...new Set(resData.map((item: IUser | any) => item[locationKey]))].forEach(
      (val: any, i: number) => {
        listData = { ...listData, [val]: COLORSlIST[i % COLORSlIST.length] };
      }
    );
    setColorList(listData);
  }

  useEffect(() => {
    getUsers();
  }, [filters?.[departmentKey]?.data?.label]);

  const getUsers = async () => {
    if (!filters?.[departmentKey]?.data?.label) return;
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
    try {
      const params = {
        functionalArea: btoa(filters?.[functionalAreaKey]?.data?.label),
        department: btoa(filters?.[departmentKey]?.data?.label),
        startDate: getStartOfDay(dateRange.startDate),
        endDate: getEndOfDay(dateRange.endDate),
      };
      await getAllusers(params).then((res: any) => {
        const resData = res.data?.data;
        setMasterUsersData(resData);
        setUsersData(getHrsData(resData));
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "INFO",
            msg: `Data has been successfully synced.`,
            timeout: 3000,
          },
        });
      });
    } catch (error) {}
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
  };

  useEffect(() => {
    setPieChartData(getHrsData(masterData));
    setUsersData(getHrsData(masterUsersData));
  }, [activeCategories]);

  const formattData = (allData: IUser[], key: string): IGraphData[] => {
    return (
      [...new Set(allData.map((item: IUser | any) => item[key]))] as string[]
    ).map((value: string) => {
      return {
        value: allData
          .filter((item: IUser | any) => item[key] === value)
          .reduce(
            (prevVal: any, currVal: any) => prevVal + currVal.totalHrs,
            0
          ),
        name: value?.match(/.{1,25}/g)?.join("\n"),
        label: value,
      };
    }) as IGraphData[];
  };

  function getHrsData(resData: any) {
    return resData
      .map((item: any) => {
        let tempData = {};
        if (item.timespent) {
          Object.keys(item.timespent).forEach((key) => {
            if (
              categories.length === activeCategories.length ||
              activeCategories.length === 0 ||
              activeCategories.includes(key)
            )
              tempData = {
                ...tempData,
                [key]:
                  // Math.floor(Math.random() * 100000000) ||
                  item.timespent[key],
              };
          });
        }
        return {
          ...item,
          timespent: tempData,
          totalHrs: Object.values(tempData).reduce(
            (a: any, b: any) => a + b,
            0
          ),
        };
      })
      .filter(
        (item: any) =>
          item.totalHrs > 0 || item?.billableHours > 0 || item?.swipeInOut > 0
      );
  }

  const getFormattedDepartments = (data: any) => {
    return (
      [
        ...new Set(data.map((item: IUser | any) => item[departmentKey])),
      ] as string[]
    ).map((dept: string) => {
      const tempHrsData = data.filter(
        (item: any) => item[departmentKey] === dept
      );

      const tempCatTimeData = tempHrsData.map((item: any) => item.timespent);
      let tempTimeSpent = {};
      categories.map((cat) => {
        if (
          categories.length === activeCategories.length ||
          activeCategories.length === 0 ||
          activeCategories.includes(cat)
        ) {
          tempTimeSpent = {
            ...tempTimeSpent,
            [cat]: tempCatTimeData.reduce(
              (prevVal: any, currVal: any) => prevVal + currVal[cat] || 0,
              0
            ),
          };
        }
      });

      return {
        [departmentKey]: dept,
        totalHrs: tempHrsData.reduce((a: any, b: any) => a + b.totalHrs, 0),
        timespent: tempTimeSpent,
      };
    });
  };

  const getDeptHeader = () => {
    return (
      <div>
        {filters?.[functionalAreaKey]?.data?.label && (
          <label className="dds-body">
            {filters?.[functionalAreaKey]?.data?.label}
          </label>
        )}
        {filters?.[departmentKey]?.data?.label && (
          <label className="dds-body">{` - ${filters?.[departmentKey]?.data?.label}`}</label>
        )}
        {filters?.[subDepartmentKey]?.data?.label && (
          <label className="dds-body">{` - ${filters?.[subDepartmentKey]?.data?.label}`}</label>
        )}
      </div>
    );
  };

  const getLocHeader = () => {
    return (
      <div>
        {filters?.[locationKey]?.data?.label && (
          <>
            <label className="dds-body">
              {filters?.[locationKey]?.data?.label}
            </label>
          </>
        )}
      </div>
    );
  };

  const getLocPiechartData = () =>
    pieChartData.filter(
      (item: IUser) =>
        (!filters?.[functionalAreaKey] ||
          filters?.[functionalAreaKey]?.data?.label === item.functionalArea) &&
        (!filters?.[departmentKey] ||
          filters?.[departmentKey]?.data?.label === item.department) &&
        (!filters?.[subDepartmentKey] ||
          filters?.[subDepartmentKey]?.data?.label === item.subDepartment)
    );

  const getPiechartData = () =>
    pieChartData.filter(
      (item: IUser) =>
        (!filters?.[locationKey] ||
          filters?.[locationKey]?.data?.label === item.officeLocation) &&
        (activeGraphKey === functionalAreaKey ||
          (activeGraphKey !== functionalAreaKey &&
            (!filters?.[functionalAreaKey] ||
              filters?.[functionalAreaKey]?.data?.label ===
                item.functionalArea))) &&
        (activeGraphKey === departmentKey ||
          (activeGraphKey !== departmentKey &&
            (!filters?.[departmentKey] ||
              filters?.[departmentKey]?.data?.label === item.department))) &&
        (activeGraphKey === subDepartmentKey ||
          (activeGraphKey !== subDepartmentKey &&
            (!filters?.[subDepartmentKey] ||
              filters?.[subDepartmentKey]?.data?.label === item.subDepartment)))
    );

  const piecharData = getPiechartData();

  const getUsersData = () => {
    return usersData
      .slice()
      .filter(
        (item: any) =>
          (!filters?.[locationKey] ||
            filters?.[locationKey]?.data?.label === item.officeLocation) &&
          (!filters?.[subDepartmentKey] ||
            filters?.[subDepartmentKey]?.data?.label === item.subDepartment)
      );
  };

  const noDataGraph = () => (
    <div className={`donutChart-container ${BASE_CLASS}-noData emptyScreen`}>
      <div>No data is available for the selected dates.</div>
      <p>Please try different dates.</p>
    </div>
  );
  const funDeptSubDeptData = formattData(piecharData, activeGraphKey);
  const locationsData = formattData(getLocPiechartData(), locationKey);
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-tabs no_highlights`}>
        {categories?.map((tab: string, i: number) => (
          <Fragment key={tab}>
            <div
              onClick={() => {
                if (activeCategories.includes(tab)) {
                  setActiveCategories([
                    ...activeCategories.filter((item) => item !== tab),
                  ]);
                } else {
                  setActiveCategories([...activeCategories, tab]);
                }
              }}
              className={`${BASE_CLASS}-tabs-tab${
                activeCategories.includes(tab) ? "-active" : ""
              } dds-body-semibold`}
            >
              {tab}
            </div>
            {i < categories.length - 1 ? (
              <div className={`${BASE_CLASS}-tabs-divider`} />
            ) : (
              ""
            )}
          </Fragment>
        ))}
      </div>
      <div className={`${BASE_CLASS}-daterange`}>
        <DateRangePicker
          size={Size.l}
          theme={themeObjState}
          maxDate={baseDate}
          labelPosition="external"
          label={"Start and end date"}
          disabledDates={{ after: new Date(), dates: [new Date()] }}
          isManualInput={false}
          format="DD/MM/YYYY"
          icon="calendar__s__stroke"
          customClass="daterange"
          value={[dateRange.startDate, dateRange.endDate]}
          dateRangeSelected={(data: string[] | Date[] | null) => {
            if (data && data.length === 2) {
              setDateRange({
                startDate: new Date(data[0]),
                endDate: new Date(data[1]),
              });
            }
          }}
        />
      </div>
      <div className={`${BASE_CLASS}-divider`} />
      <div className={`${BASE_CLASS}-header dds-h6`}>
        Organisation analytics
      </div>
      <div className={`${BASE_CLASS}-analytics`}>
        <Analytics
          analytics={analyticsData}
          activeCategories={activeCategories}
        />
      </div>
      <div
        className={`${BASE_CLASS}-header dds-h6`}
        style={{ marginTop: "4vh" }}
      >
        Organisation analytics - graphical representation
      </div>
      <NotifyToast
        type={"INFO"}
        message={
          "Click on the graph to select or deselect specific areas for a detailed view."
        }
      />
      <div className={`${BASE_CLASS}-graph-container`}>
        <div className={`${BASE_CLASS}-graph`} style={{ paddingTop: "12px" }}>
          <label className={`${BASE_CLASS}-graph-label dds-h5`}>
            {activeGraphKey === functionalAreaKey
              ? "Functions"
              : activeGraphKey === departmentKey
              ? "Departments"
              : "Sub-departments"}
          </label>
          <div className={`${BASE_CLASS}-graph-label-sub dds-h7`}>
            {getLocHeader()}
          </div>
          {funDeptSubDeptData.length ? (
            <>
              <div
                className={`${BASE_CLASS}-graph-back cursor ${
                  activeGraphKey === functionalAreaKey && "disabled"
                }`}
                onClick={() => {
                  if (filters[subDepartmentKey]) {
                    setFilters({ ...filters, subDepartment: undefined });
                    return;
                  }
                  if (activeGraphKey === departmentKey) {
                    setActiveGraphKey(functionalAreaKey);
                    setFilters({
                      ...filters,
                      [functionalAreaKey]: undefined,
                      [departmentKey]: undefined,
                      [subDepartmentKey]: undefined,
                    });
                  } else if (activeGraphKey === subDepartmentKey) {
                    setActiveGraphKey(departmentKey);
                    setFilters({
                      ...filters,
                      [departmentKey]: undefined,
                      [subDepartmentKey]: undefined,
                    });
                  }
                }}
              >
                <BackIcon />
                Back
              </div>
              <DonutChart
                colorList={colorList}
                data={funDeptSubDeptData}
                active={filters[activeGraphKey]}
                chartType={activeGraphKey}
                onChange={(val: any) => {
                  setFilters({ ...filters, [activeGraphKey]: val });
                  if (val?.data?.label) {
                    if (
                      activeGraphKey === functionalAreaKey &&
                      piecharData.some(
                        (item: any) =>
                          item.functionalArea === val?.data?.label &&
                          item.department
                      )
                    ) {
                      setActiveGraphKey(departmentKey);
                    } else if (
                      activeGraphKey === departmentKey &&
                      piecharData.some(
                        (item: any) =>
                          item.department === val?.data?.label &&
                          item.subDepartment
                      )
                    ) {
                      setActiveGraphKey(subDepartmentKey);
                    }
                  }
                }}
              />
            </>
          ) : (
            noDataGraph()
          )}
        </div>
        <div className={`${BASE_CLASS}-graph`} style={{ paddingTop: "12px" }}>
          <label className={`${BASE_CLASS}-graph-label dds-h5`}>
            Locations
          </label>
          <div className={`${BASE_CLASS}-graph-label-sub dds-h7`}>
            {getDeptHeader()}
          </div>
          {locationsData.length > 0 ? (
            <>
              <div className={`${BASE_CLASS}-graph-label-sub dds-h7`} />
              <DonutChart
                colorList={colorList}
                position={"left"}
                data={locationsData}
                active={filters?.[locationKey]}
                chartType={locationKey}
                onChange={(val: any) => {
                  setFilters({ ...filters, [locationKey]: val });
                }}
              />
            </>
          ) : (
            noDataGraph()
          )}
        </div>
      </div>
      {filters?.[functionalAreaKey] ? (
        <div className={`${BASE_CLASS}-hours-graph-container`}>
          <div style={{ padding: "0 2vh" }}>
            <div className="dds-h5" style={{ padding: "2vh 0 1vh" }}>
              Hours
            </div>
            {getDeptHeader()}
            {getLocHeader()}
          </div>
          {filters?.[departmentKey]?.data?.label ? (
            <HoursChart
              data={getUsersData()}
              itemKey={"name"}
              legends={(activeCategories.length === 0
                ? categories
                : activeCategories
              ).map((label, i) => ({
                label,
                color:
                  CAT_COLORS_LIST?.[label?.toUpperCase()] ||
                  COLORSlIST[i % COLORSlIST.length],
              }))}
            />
          ) : (
            <HoursChart
              data={getFormattedDepartments(piecharData)}
              itemKey={departmentKey}
              legends={(activeCategories.length === 0
                ? categories
                : activeCategories
              ).map((label, i) => ({
                label,
                color:
                  CAT_COLORS_LIST?.[label?.toUpperCase()] ||
                  COLORSlIST[i % COLORSlIST.length],
              }))}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrgLevel;
