import { CSSProperties, FC, KeyboardEventHandler, useState } from "react";
import "./InputBox.scss";

const validateName = (value: any, minLength: number) =>
  /^[ 0-9a-zA-Z-_]+$/.test(value) && value.length >= minLength;
const validateUserUsername = (value: any, minLength: number) =>
  /^[0-9a-zA-Z]+$/.test(value) && value.length >= minLength;
const validateUserFirstOrLastName = (value: any, minLength: number) =>
  /^[ a-zA-Z]+$/.test(value) && value.length >= minLength;
const validateDesc = (value: any, minLength: number) =>
  value.length >= minLength;
const validatePassword = (value: any, minLength: number) =>
  value.length >= minLength;
const validateRequired = (value: any) => value.trim() !== "";
const validateEmail = (value: any) =>
  /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value);
const validateFileName = (value: any) =>
  /^([A-Za-z0-9_]+)$/.test(value);

interface InputBoxInterface {
  mainClassName?: string;
  inputBoxClassName?: string;
  placeholder?: string;
  onChange?(newValue: string): void;
  value?: string;
  style?: CSSProperties;
  addLabel?: boolean;
  labelText?: string;
  errorText?: string;
  disabled?: boolean;
  type?: string;
  readOnly?: boolean;
  maxLength?: number;
  minLength?: number;
  validators?: any;
  onInputChange?: any;
  isRequired?: boolean;
  isError?: boolean;
  autoFocus?: boolean;
  onKeyDown?: KeyboardEventHandler;
  suffix?: string;
}

export const InputBox: FC<InputBoxInterface> = ({
  mainClassName = "",
  inputBoxClassName,
  placeholder,
  style = {},
  onChange,
  value = "",
  addLabel,
  labelText,
  errorText,
  disabled = false,
  readOnly,
  maxLength = 1024,
  minLength = 0,
  type,
  validators,
  onInputChange,
  isRequired,
  isError,
  autoFocus = true,
  onKeyDown,
  suffix,
}) => {
  // const [inputText, setInputText] = useState<string>("");
  const [error, setError] = useState("");

  const handleValidation = (inputValue: any) => {
    for (const validator of validators) {
      const isValid = validator(inputValue, minLength);
      if (isRequired && inputValue.length === 0) {
        setError(`${labelText} required.`);
        return false;
      }
      if (!isValid) {
        setError(`Invalid ${errorText}`);
        return false;
      }
    }
    if (inputValue.length < minLength) {
      setError(`Invalid ${errorText}`);
      return false;
    }
    if (inputValue.length > maxLength) {
      setError(`Invalid ${errorText}`);
      return false;
    }
    setError("");
    return true;
  };

  const handleChange = (event: any) => {
    const inputValue = event.target.value;
    onChange?.(inputValue);
    const isValid = handleValidation(inputValue);
    onInputChange(labelText, isValid);
  };

  return (
    <div className={`inputbox-container ${mainClassName}`}>
      {addLabel ? (
        <label className="--label-box dds-label">
          {labelText}
          {isRequired && <span className="error">*</span>}
        </label>
      ) : null}
      <div
        style={style}
        className={`--inputbox-main ${inputBoxClassName} ${error && "inValid"}`}
      >
        <input
          type={type ? type : "text"}
          disabled={disabled}
          style={
            disabled
              ? { cursor: "not-allowed", pointerEvents: "all" }
              : { cursor: "pointer" }
          }
          autoFocus={autoFocus}
          readOnly={readOnly}
          placeholder={placeholder}
          value={value}
          minLength={minLength}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
        {suffix && <span className="suffix dds-label">{suffix}</span>}
      </div>
      {error && <p className="error-msg">{error}</p>}
      {!error && isError && <p className="error-msg">{errorText}</p>}
    </div>
  );
};

export {
  validateRequired,
  validateName,
  validateDesc,
  validateUserFirstOrLastName,
  validateUserUsername,
  validateEmail,
  validatePassword,
  validateFileName
};
