import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Button, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import "./ApprovalsAndRequests.scss";
import ChangeLogTabel from "./changeLogTabel/ChangeLogTabel";
import CategoryService from "services/CategoryService";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch, useSelector } from "react-redux";
import { approvalStatusEnum } from "global/constants/Enums";
import { NotifyUndo } from "global/components/notify/NotifyUndo";
import { RootState } from "app/store";

const BASE_CLASS = "approvalsAndRequests";
const ApprovalsAndRequests: FC = () => {
  const dispatch = useDispatch();
  const { themeObjState } = useContext(ThemeContext);
  const [allData, setAllData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<
    Record<string, any> | undefined
  >();
  const [categories, setCategories] = useState();

  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );

  const hasApproveEntitled: boolean =
    LoginUserData?.entitlements?.permissions.some(
      (item: { endpoint: string; requestType: string }) =>
        item.endpoint === "changeEvents" && item.requestType === "PUT"
    ) || false;

  const sidebarButtons = [
    {
      label: hasApproveEntitled ? "Approve requests" : "Raised requests",
      value: "risedReq",
      status: [approvalStatusEnum.PENDING.toString()],
    },
    {
      label: "History",
      value: "history",
      status: [
        approvalStatusEnum.APPROVED.toString(),
        approvalStatusEnum.REJECTED.toString(),
      ],
    },
  ];
  const [active, setActive] = useState(sidebarButtons[0]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

      let filtersData: Record<string, string> = {
        functionalArea: "",
        department: "",
      };
      if (LoginUserData?.subDepartment) {
        filtersData = {
          ...filtersData,
          subDepartment: "",
        };
      }
      let params = {};
      Object.keys(filtersData).forEach((key) => {
        if (filtersData[key] && filtersData[key] !== "*") {
          params = { ...params, [key]: btoa(filtersData[key]) };
        }
      });
      const resData: any = await CategoryService.getAll(params);
      const CategoriesData = resData.data.data;
      setCategories(CategoriesData);
      const res = await CategoryService.getAllChangeRequest(params);

      setAllData(
        res.data.data.map((item: any) => formattData(item, CategoriesData))
      );
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Failed to load`,
          timeout: 3000,
        },
      });
    }
  };

  const formattData = (item: any, CategoriesData: any = categories) => {
    const fromCatName =
      CategoriesData.filter((cat: any) => cat.id === item.fromCategory)?.[0]
        ?.name || "Uncategorised";

    let fromSubCatName = "";
    if (fromCatName !== "Uncategorised" && item?.fromSubCategory) {
      fromSubCatName = ` - ${
        CategoriesData.filter(
          (cat: any) => cat.id === item.toCategory
        )?.[0]?.subcategories.filter(
          (subCat: any) => subCat.id === item?.fromSubCategory
        )?.[0]?.name || ""
      }`;
    }
    const toCatName = `${
      CategoriesData.filter((cat: any) => cat.id === item.toCategory)?.[0]
        ?.name || ""
    } - ${
      CategoriesData.filter(
        (cat: any) => cat.id === item.toCategory
      )?.[0]?.subcategories.filter(
        (subCat: any) => subCat.id === item?.toSubCategory
      )?.[0]?.name || ""
    }`;
    return {
      ...item,
      fromCatName: fromCatName + fromSubCatName,
      toCatName,
    };
  };

  const updateChangeReq = async (updateData: any, isUndo?: boolean) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      const res = await CategoryService.updateChangeReq(updateData.id, {
        ApprovalStatus: updateData.ApprovalStatus,
      });

      setAllData(
        allData.map((item: any) => {
          if (item.id === updateData.id) {
            return formattData({ ...res.data.data });
          } else {
            return { ...item };
          }
        })
      );
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      setSelectedData(isUndo ? undefined : updateData);
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Failed to update`,
          timeout: 6000,
        },
      });
    }
  };
  return (
    <>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}-sidebar`}>
          {sidebarButtons?.map((item: any, i: number) => (
            <Fragment key={item.value}>
              <Button
                size={Size.m}
                kind={
                  active.value === item.value ? Kind.primaryLoud : Kind.silent
                }
                className={
                  active.value === item.value
                    ? ""
                    : `${BASE_CLASS}-sidebar-option`
                }
                theme={themeObjState}
                label={item.label}
                onClick={() => {
                  setActive(item);
                }}
              />
              {i < sidebarButtons.length - 1 && (
                <div
                  className="divider"
                  style={{ marginBottom: "8px", marginTop: "8px" }}
                ></div>
              )}
            </Fragment>
          ))}
        </div>
        <div className={`${BASE_CLASS}-content`}>
          <ChangeLogTabel
            active={active}
            data={allData.filter((item) =>
              active.status.includes(item.ApprovalStatus)
            )}
            hasApproveEntitled={hasApproveEntitled}
            updateChangeReq={(data: any) => updateChangeReq(data)}
          />
        </div>
      </div>
      {selectedData && (
        <NotifyUndo
          notify={{
            type:
              selectedData.ApprovalStatus === approvalStatusEnum.APPROVED
                ? "SUCCESS"
                : "ERROR",
            msg: `${selectedData.appName}'s request has been ${selectedData.ApprovalStatus}`,
            timeout: 6000,
          }}
          undoClick={() =>
            updateChangeReq(
              {
                ...selectedData,
                ApprovalStatus: selectedData?.prevStatus,
              },
              true
            )
          }
          setSelectedData={setSelectedData}
        />
      )}
    </>
  );
};
export default ApprovalsAndRequests;
