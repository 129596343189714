import { FC, memo, useEffect } from "react";
import * as echarts from "echarts";
import { format_HR_MIN_SEC } from "global/utils/DateUtil";
import COLORSlIST from "global/constants/Colors";

type EChartsOption = echarts.EChartsOption;

interface IDonutChartProps {
  data: any;
  chartType: string;
  onChange: (val: any) => void;
  active?: any;
  custOptions?: EChartsOption;
  position?: string;
  colorList?: Record<string, string>;
}
const DonutChart: FC<IDonutChartProps> = ({
  data,
  chartType,
  onChange,
  active,
  custOptions,
  position = "right",
  colorList,
}) => {
  useEffect(() => {
    // if (data?.length) {
    displaychart(data);
    // }
  }, [data, active]);

  function displaychart(data: any) {
    const chartData = data?.sort((a: { name: string }, b: { name: string }) =>
      a.name > b.name ? 1 : -1
    );
    const chartDom = document.getElementById(`donutChart-${chartType}`);
    if (!chartDom) return;
    const myChart =
      echarts.getInstanceByDom(chartDom) || echarts.init(chartDom);

    const option: EChartsOption = {
      // tooltip: {
      //   trigger: "item",
      // },
      legend: {
        type: "scroll",
        icon: "rect",
        orient: "vertical",
        right: 0,
        top: "center",
        borderRadius: 0,
        itemWidth: 20,
        itemHeight: 20,
        selectedMode: false,
        itemStyle: {
          borderCap: "square",
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          // emphasis: {
          //   label: {
          //     show: true,
          //     fontSize: 40,
          //     fontWeight: "bold",
          //   },
          // },
          left: "-25%",
          labelLine: {
            show: false,
          },
          color: colorList
            ? chartData.map((item: any) => colorList[item?.label])
            : COLORSlIST,
          data: chartData,
        },
      ],
      tooltip: {
        trigger: "item",
        // trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        borderRadius: 0,
        position: (pos, params, dom, rect, size) => {
          if (position === "right") {
            return [pos[0] + 15, pos[1] + 20];
          } else {
            return [pos[0] - size.contentSize[0] - 15, pos[1] + 20];
          }
        },
        formatter: function (params: any) {
          return (
            `<span style="color: ${params?.color}"> ` +
            params.data?.label +
            " - " +
            format_HR_MIN_SEC(params.data?.value / 1000) +
            "</span>"
          );
        },
      },
      ...custOptions,
    };

    option && myChart.setOption(option);
    // myChart.on("legendselectchanged", function (params: any) {
    //   console.log(
    //     "legendselectchanged",
    //     params.data,
    //     params.seriesIndex,
    //     params.dataIndex,
    //     params
    //   );
    // });

    myChart.off("click").on("click", (params: any) => {
      // console.log("click", params.data, params.seriesIndex, params.dataIndex);
      if (active) {
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: active.seriesIndex,
          dataIndex: active.dataIndex,
        });
        if (active.dataIndex === params.dataIndex) {
          onChange(undefined);
          return;
        }
      }
      onChange(params);
      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: params.seriesIndex,
        dataIndex: params.dataIndex,
      });
    });
  }
  return (
    <div id={`donutChart-${chartType}`} className={`donutChart-container`} />
  );
};

export default memo(DonutChart);
