import * as act from "./action";

interface StateInterface {
  activePage: { page: "LANDING" | "CREATEPROCESS" };
  //   activeTab: { tab: 'PROCESSES' | 'USERS' | 'REPOSITORY' };
  currentProcess: string;
  globalUsers: any[];
}

const initialState: StateInterface = {
  activePage: { page: "LANDING" },
  //   activeTab: { tab: 'PROCESSES' },
  currentProcess: "",
  globalUsers: [],
};

type actionType = {
  type: string;
  payload: any;
};

export default function homeReducer(state = initialState, action: actionType) {
  switch (action.type) {
    // case act.UPDATE_ACTIVEHOMETAB:
    //   if (!action.payload.tab) action.payload.tab = state.activeTab.tab;
    //   return { ...state, activeTab: action.payload };
    case act.UPDATE_ACTIVEPAGE:
      if (!action.payload.page) action.payload.page = state.activePage.page;
      return { ...state, activePage: action.payload };
    // case act.UPDATE_CURRENTPROCESS:
    //   if (!action.payload.currentProcess) action.payload.currentProcess = state.currentProcess;
    //   return { ...state, currentProcess: action.payload.currentProcess };
    case act.GLOBAL_USERS:
      return {
        ...state,
        globalUsers: [
          ...state.globalUsers.filter(
            (user) =>
              !action.payload
                .map((item: { email: string }) => item.email)
                .some((email) => email === user.email) && user.created
          ),
          ...action.payload,
        ],
      };

    default:
      return state;
  }
}
