import { FC, useState } from "react";
import { FilterIcon, TickIcon } from "global";
import ClickAwayListener from "react-click-away-listener";

interface listDataProp {
  label: string;
  value: string;
  selected?: boolean;
}
interface FilterCompProps {
  status: listDataProp[];
  setFilters: (val: string[]) => void;
}
export const FilterComp: FC<FilterCompProps> = ({
  status,
  setFilters,
}: FilterCompProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const BASE_CLASS = "filter";

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <img
        data-testid="filterIcon"
        className="cursor"
        src={FilterIcon}
        onClick={handleClose}
        alt="filter"
      />
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={`${BASE_CLASS}-container`}>
            <div className={`${BASE_CLASS}-container-box`}>
              <div className={`${BASE_CLASS}-container-box-body dds-body`}>
                <span className={`dds-label-semibold ${BASE_CLASS}-title`}>
                  Filter
                </span>
                <div className={`${BASE_CLASS}-checkbox-overflow`}>
                  {status.map((option: listDataProp, i: number) => (
                    <div
                      className={`${BASE_CLASS}-checkbox`}
                      key={i}
                      onClick={() => {
                        setFilters(
                          status
                            .filter(
                              (opt: listDataProp) =>
                                (option.value === opt.value && !opt.selected) ||
                                (option.value !== opt.value && opt.selected)
                            )
                            .map((opt) => opt.value)
                        );
                        setIsOpen(false);
                      }}
                    >
                      {option.label}
                      {option?.selected && <img src={TickIcon} />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
