import { CSSProperties, FC, useEffect, useState } from "react";
import Select, {
  components,
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import COLORS from "../../../locale.json";
import "./Dropdown.scss";
import {
  CloseWhiteIconSvg,
  DownArrowIcon,
  PopUpModel,
  SearchIcon,
} from "global";
// import { Size, StickerPosition } from "@usitsdasdesign/dds-react";

interface OptionsInterface {
  label: string;
  options?: { label: string; value: string }[];
  value?: string | IApp | ICategory;
  disabled?: boolean;
  onClick?: any;
}

interface DropdownInterface {
  style?: CSSProperties;
  className?: string;
  isSearchable?: boolean;
  options: OptionsInterface[];
  onchange: (
    newValue: SingleValue<OptionsInterface> | MultiValue<OptionsInterface>,
    actionMeta: ActionMeta<OptionsInterface>
  ) => void;
  placeholder: string;
  showValue?: boolean;
  borderHide?: boolean;
  disabled?: boolean;
  controlStyle?: CSSProperties;
  dropdownIndicatorStyle?: CSSProperties;
  placeholderStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  isMulti?: boolean;
  addLabel?: boolean;
  isRequired?: boolean;
  labelText?: string;
  showPrompt?: boolean;
  defaultValue?: any; //OptionsInterface | OptionsInterface[], union doesn't work :(
  ref?: any;
  showChips?: boolean;
  controlShouldRenderValue?: boolean;
  isClearable?: boolean;
  isSearchBox?: boolean;
  customFilter?: (option: any, searchText: string) => boolean;
  dropDownCmpStyle?: CSSProperties;
  selectAllText?: string;
  viewOnly?: boolean | undefined;
}
interface StateInterface {
  isopen: boolean;
  value: any;
}
const cnDefault = {};
const diDefault = {};
const phDefault = {};
const coDefault = {};

const Menu = (props: any) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundcolor: "white",
        borderradius: 4,
        boxshadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        margintop: 8,
        position: "absolute",
        zindex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zindex: 1,
    }}
    {...props}
  />
);
const DropDownCmp = ({
  children,
  isopen,
  target,
  onclose,
  isDisabled = false,
  dropDownCmpStyle = {},
}: {
  children: any;
  isopen: any;
  target: any;
  onclose: any;
  isDisabled?: boolean;
  dropDownCmpStyle?: CSSProperties;
}) => (
  // <div style={{ position: 'relative', width: '65%', opacity: isDisabled ? 0.4 : 1 }}>
  <div style={{ opacity: isDisabled ? 0.4 : 1, ...dropDownCmpStyle }}>
    {target}
    {isopen ? <Menu>{children}</Menu> : null}
    {isopen ? <Blanket onClick={onclose} /> : null}
  </div>
);

export const Dropdown: FC<DropdownInterface> = ({
  style = {},
  className = "",
  controlStyle = cnDefault,
  dropdownIndicatorStyle = diDefault,
  placeholderStyle = phDefault,
  containerStyle = coDefault,
  dropDownCmpStyle = {},
  options,
  showValue = false,
  defaultValue = { label: "", value: "" },
  showPrompt = false,
  placeholder,
  onchange,
  isSearchable = false,
  borderHide = false,
  disabled = false,
  isMulti = false,
  addLabel,
  isRequired,
  labelText,
  ref,
  showChips = false,
  controlShouldRenderValue = true,
  isClearable = true,
  isSearchBox = false,
  selectAllText = "Select all",
  customFilter = undefined,
  viewOnly = undefined,
}) => {
  const [selectedValue, setSelectedValue] = useState<OptionsInterface[]>([]);
  const [promptValue, setPromptValue] = useState<Record<string, any>>({});
  const [custstyles, setCuststyles] = useState<
    StylesConfig<OptionsInterface, boolean, GroupBase<OptionsInterface>>
  >({});
  const [state, setState] = useState<StateInterface>({
    isopen: false,
    value: undefined,
  });
  const [singleSelectedValue, setSingleSelectedValue] =
    useState<OptionsInterface>();
  const toggleopen = () => {
    setState((prevState: StateInterface) => ({
      ...prevState,
      isopen: !prevState.isopen,
    }));
  };
  let selectAll = false;
  let customOptions = options;

  let Components: any = undefined;

  if (isMulti && options.length > 0) {
    const handleSelectAll = () => {
      selectAll = true;
    };
    customOptions = [
      { label: selectAllText, value: "select-all", onClick: handleSelectAll },
      ...options,
    ];
  }

  if (isMulti) {
    Components = {
      MultiValueContainer: ({ selectProps, data }: any) => {
        const values: any[] = selectProps.value;

        if (options.length === 0) {
          setSelectedValue([]);
        }

        if (values) {
          return values.some((val) => val.value === "select-all")
            ? values.findIndex((val) => val.value === data.value) > 0
              ? ""
              : "All"
            : values.findIndex((val) => val.value === data.value) > 0
              ? ""
              : values.length > 1
                ? data.label + ` +${values.length - 1}`
                : data.label;
        } else return "";
      },
      Option: (props: any) => {
        return (
          <>
            <components.Option {...props}>
              {/* <div  style={{display:"flex"}}>
                                <div>
                                    <input
                                    className='dropdown-checkbox fs-16'
                                    type="checkbox"
                                    checked={props.isSelected}
                                    onChange={() => null}
                                />{" "}
                                    <span className="checkmark"></span>
                                </div>
                                <div>
                                    <label
                                        className='dropdown-label  fs-16'
                                    >{props.label}</label>
                                </div>
                            </div> */}
              <div
                className="tooltip-container"
                style={{ width: "100%" }}
                onClick={props.data.onClick}
              >
                <label className="container" style={{ paddingLeft: "25px" }}>
                  <span
                    className="fs-16 dropdown-item"
                    style={{ maxWidth: "360px" }}
                  >
                    {props.label}
                  </span>
                  <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </components.Option>
            {/* <components.MultiValueLabel {...props}>
                            {getValue(props)}
                        </components.MultiValueLabel> */}
          </>
        );
      },
    };
  } else {
    Components = {
      Option: (props: any) => {
        return (
          <>
            <components.Option {...props}>
              <div className="tooltip-container">
                <label className="container">
                  <span
                    className="fs-16 dropdown-item"
                    style={{ maxWidth: "385px" }}
                  >
                    {props.label}
                  </span>
                </label>
              </div>
            </components.Option>
          </>
        );
      },
    };
  }
  if (isSearchBox) {
    const Input = (inputProps: any) => {
      // const getValue = inputProps.getValue;
      const options = inputProps.options;
      /*console values and otpions for custom input
      console.log('value', getValue());
      console.log(options);*/
      return (
        <input
          autoFocus={true}
          className="dropdown-custom-input"
          readOnly={options.length === 0}
          placeholder="Search"
          {...inputProps}
        />
      );
    };
    const ValueContainer = ({ children, ...props }: any) => {
      return (
        <components.ValueContainer {...props}>
          <img src={SearchIcon} alt="search" />
          {children}
        </components.ValueContainer>
      );
    };
    Components = { ...Components, Input, ValueContainer };
  }

  useEffect(() => {
    if (isMulti && defaultValue) {
      if (defaultValue.length === options.length) {
        selectAll = true;
        setSelectedValue([
          {
            label: selectAllText,
            value: "select-all",
            onClick: () => (selectAll = false),
          },
          ...defaultValue,
        ]);
      } else {
        setSelectedValue([...defaultValue]);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!isMulti && defaultValue) {
      setSelectedValue(defaultValue);
    }
    if (isSearchBox && !isMulti) {
      setSingleSelectedValue(
        !defaultValue.value
          ? { value: placeholder, label: placeholder }
          : defaultValue
      );
    }
  }, [defaultValue.value]);

  useEffect(() => {
    setCuststyles({
      indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: COLORS.BORDERCOLOR,
        ":hover": {
          color: COLORS.BLACKCOLOR,
        },
        ...dropdownIndicatorStyle,
      }),
      menuPortal: (styles) => ({
        ...styles,
        zIndex: 100,
        fontSize: "12px",
      }),
      group: (styles) => ({
        ...styles,
        borderBottom: `1px solid ${COLORS.BORDERCOLOR}`,
        ":last-child": {
          borderBottom: "None",
        },
      }),
      control: (styles) => ({
        ...styles,
        fontWeight: 500,
        border: borderHide ? `none` : `1px solid ${COLORS.BORDERCOLOR}`,
        borderRadius: "0px",
        boxShadow: "none",
        cursor: "pointer",
        textAlign: "left",
        ":hover": {
          border: borderHide ? `none` : `1px solid ${COLORS.BORDERCOLOR}`,
        },
        ":active": {
          border: borderHide ? `none` : `1px solid ${COLORS.BORDERCOLOR}`,
        },
        zIndex: 1,
        ...controlStyle,
      }),
      menu: (base) => ({
        ...base,
        borderRadius: "0px",
        zIndex: 100,
      }),
      singleValue: (styles) => ({
        ...styles,
        color: COLORS.BLACKCOLOR,
        ...placeholderStyle,
      }),
      valueContainer: (styles) => ({ ...styles, ...containerStyle }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        fontWeight: 500,
        color: isSelected ? "black" : "black",
        background:
          // : isSelected:
          //   ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF'
          "#fff",
        zIndex: 1,
        ":hover": {
          background: isSelected
            ? "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF"
            : isFocused
              ? "linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF"
              : "#fff",
        },
      }),
      placeholder: (styles) => ({
        ...styles,
        color: COLORS.BORDERGREY,
        ...placeholderStyle,
      }),
    });
  }, [
    controlStyle,
    dropdownIndicatorStyle,
    placeholderStyle,
    containerStyle,
    borderHide,
  ]);
  const onValuechange = (newValue: any, actionMeta: any) => {
    if (selectedValue === newValue) return;
    if (isSearchBox && !isMulti) {
      toggleopen();
      setSingleSelectedValue(newValue);
    }
    if (showPrompt && isMulti && selectedValue.length > newValue.length) {
      const removedValue = selectedValue.filter(
        (newVal: OptionsInterface) =>
          !newValue.find((val: OptionsInterface) => val.label === newVal.label)
      )[0];
      setPromptValue({ removedValue, newValue, actionMeta });
    } else {
      if (selectAll) {
        selectAll = false;
        if (selectedValue.length === customOptions.length) {
          setSelectedValue([]);
          onchange([], actionMeta);
        } else {
          setSelectedValue(customOptions);
          onchange(options, actionMeta);
        }
      } else {
        const isselectallpresent: boolean = isMulti;
        if (
          newValue.length === customOptions.length - 1 &&
          !isselectallpresent
        ) {
          setSelectedValue(customOptions);
          onchange(options, actionMeta);
        } else if (
          newValue.length === customOptions.length - 1 &&
          isselectallpresent
        ) {
          selectAll = true;
          setSelectedValue(
            newValue.filter((value: any) => value.value !== "select-all")
          );
          onchange(
            newValue.filter((value: any) => value.value !== "select-all"),
            actionMeta
          );
        } else {
          setSelectedValue(newValue);
          onchange(newValue, actionMeta);
        }
      }
    }
  };

  const handleChipCancel = (option: any) => {
    const actionMeta = {
      action: "deselect-option",
      name: undefined,
      option: option,
    };
    const newValue = selectedValue.filter(
      (value) => value.value !== option?.value
    );
    onValuechange(newValue, actionMeta);
  };
  return (
    <div
      className={`dropdown-main dds-body-semibold noselect ${className} ${
        disabled ? "disabled-class" : ""
      }`}
      style={style}
    >
      {addLabel ? (
        <label className="dds-label" style={{ color: "#000000" }}>
          {labelText}
          {isRequired && <span className="error">*</span>}
        </label>
      ) : null}
      <div data-testid="my-select-component">
        {isSearchBox ? (
          <DropDownCmp
            dropDownCmpStyle={dropDownCmpStyle}
            isopen={state.isopen}
            onclose={toggleopen}
            target={
              // <button iconAfter={<Chevrondown />} onclick={toggleopen} isselected={state.isopen}>
              <button
                type="button"
                onClick={
                  disabled
                    ? () => {
                      undefined;
                    }
                    : toggleopen
                }
                className="box-button"
                data-testid="select-component-dropdown-comp"
              >
                <span className="dds-h7 box-button-text">
                  {singleSelectedValue && !isMulti
                    ? singleSelectedValue.label
                    : placeholder}
                </span>
                <img src={DownArrowIcon} />
              </button>
            }
          >
            <Select
              isMulti={isMulti}
              closeMenuOnSelect={!isMulti}
              blurInputOnSelect={!isMulti}
              hideSelectedOptions={false}
              value={
                showValue
                  ? selectedValue.length > 0 &&
                    selectedValue.length === customOptions.length - 1
                    ? [
                        ...selectedValue,
                        { label: selectAllText, value: "select-all" },
                      ]
                    : selectedValue
                  : { value: placeholder, label: placeholder }
              }
              placeholder={""}
              styles={custstyles}
              isSearchable={isSearchable}
              onChange={onValuechange}
              options={customOptions}
              isDisabled={disabled}
              components={Components}
              ref={ref}
              controlShouldRenderValue={controlShouldRenderValue}
              isClearable={isClearable}
              menuIsOpen={true}
              filterOption={customFilter ? customFilter : undefined}
              menuPortalTarget={document.body}
            />
          </DropDownCmp>
        ) : (
          <Select
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect={!isMulti}
            hideSelectedOptions={false}
            value={
              showValue
                ? selectedValue
                : { value: placeholder, label: placeholder }
            }
            placeholder={placeholder}
            styles={custstyles}
            isSearchable={isSearchable}
            onChange={onValuechange}
            options={customOptions}
            isDisabled={disabled}
            components={Components}
            ref={ref}
            controlShouldRenderValue={controlShouldRenderValue}
            isClearable={isClearable}
            menuIsOpen={viewOnly ? false : undefined}
          />
        )}
      </div>
      {showChips
        ? selectedValue.length > 0 && (
          <div className="dropdown-chips dds-link-sm">
            {selectedValue
              .filter((value) => value.value !== "select-all")
              .map((value, index) => {
                return (
                  <div className="tooltip-container">
                    <div className="dropdown-chips-chip" key={index}>
                      <div className="chip-item">{value.label}</div>
                        <img
                          className="dropdown-chips-chip-cancel"
                          src={CloseWhiteIconSvg}
                          alt="close"
                          onClick={() => handleChipCancel(value)}
                        />
                    </div>
                  </div>
                );
              })
            }
          </div>
        )
        : null}
      {showPrompt && promptValue?.removedValue && (
        <PopUpModel
          Title={`Remove ${promptValue?.removedValue?.label}`}
          onClickClose={() => setPromptValue({})}
          onSaveClick={() => {
            if (promptValue?.newValue && promptValue?.actionMeta) {
              setSelectedValue(promptValue?.newValue);
              onchange(promptValue?.newValue, promptValue?.actionMeta);
            }
            setPromptValue({});
          }}
          saveButtonClass={"btn-danger"}
          saveTitle={`Remove ${promptValue?.removedValue?.label}`}
          closeTitle="Cancel"
          footerStyle={{ marginRight: 10 }}
          popupModelMainStyle={{ width: "50%" }}
        >
          <div className={`dds-body`}>
            User will lose access to unselected organisations. Are you sure you
            want to continue?
          </div>
        </PopUpModel>
      )}
    </div>
  );
};
