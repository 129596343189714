import { FC } from "react";
import "./Analytics.scss";
import { formatTimeSpent } from "global/utils/DateUtil";
import { Neutral, Productive, UnProductive, UserIcon } from "global";

interface IAnalyticsProps {
  analytics: { label: string; value: number }[];
  activeCategories: string[];
}
const ICONS = [Neutral, Productive, UnProductive, UserIcon];
const BASE_CLASS = "analytics";
const Analytics: FC<IAnalyticsProps> = ({ analytics, activeCategories }) =>
  analytics?.map((item: { label: string; value: number }, i) => (
    <Analytic
      isDisabled={
        activeCategories.length ? !activeCategories.includes(item.label) : false
      }
      key={item.label}
      itemKey={item.label}
      totalHrs={analytics.reduce((acc, obj) => acc + obj.value, 0)}
      hours={item.value}
      icon={ICONS[i % ICONS.length]}
    />
  ));

export default Analytics;

interface IAnalyticProps {
  itemKey: string;
  totalHrs: number;
  hours: number;
  icon: any;
  isDisabled: boolean;
}

const Analytic: FC<IAnalyticProps> = ({
  itemKey,
  totalHrs,
  hours,
  icon,
  isDisabled,
}) => (
  <div className={`${BASE_CLASS} ${isDisabled && "disabled"}`}>
    <img src={icon} alt={itemKey} />
    <div className={`${BASE_CLASS}-container`}>
      <div className={`${BASE_CLASS}-row`}>
        <div className="dds-h6">{itemKey} hours</div>
        <div className="dds-h6"> {itemKey} hours %</div>
      </div>
      <div className={`${BASE_CLASS}-row`}>
        <div className="dds-h5-lm">{formatTimeSpent(hours)}</div>
        <div className="dds-h5-lm">
          {(hours && totalHrs && ((hours / totalHrs) * 100).toFixed(2)) || 0}%
        </div>
      </div>
    </div>
  </div>
);
