// import { useSelector, useDispatch } from "react-redux";
//import { HomeLandingPage } from './HomeLandingPage';
import { Fragment, useEffect } from "react";
// import Sidebar from "./components/sidebar/Sidebar";
import HeaderComponent from "./components/headerComponent/HeaderComponent";
import Dashboard from "./components/dashboard/Dashboard";
// import UserManagement from "./components/userManagement/UserManagement";
// import AppRegistrationForm from "./components/appRegistrationForm/AppRegistrationForm";
import "./HomePageContainer.scss";
import PowerBIDashboard from "./components/powerBiDashboard/PowerBIDashboard";
import CategoryManagement from "./components/categoryManagement/CategoryManagement";
import ApprovalsAndRequests from "./components/approvalsAndRequests/ApprovalsAndRequests";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { ACTIVE_TAB } from "global/store/action";
import Reports from "./components/reports/Reports";
/**
 * return the component according to the page value set in reducer
 * @returns Page component
 */
const BASE_CLASS = "HomePageContainer";
export const HomePageContainer = () => {
  const dispatch = useDispatch();

  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );

  const persistReducer = useSelector(
    (state: RootState) => state.globalPersistReducer
  );

  useEffect(() => {
    dispatch({ type: ACTIVE_TAB, payload: "LANDING" });
  }, [LoginUserData.entitlements]);

  return (
    <div className={BASE_CLASS}>
      <HeaderComponent
        setPage={(value: string) =>
          dispatch({ type: ACTIVE_TAB, payload: value })
        }
        page={persistReducer.activeTab}
      />

      <div className={`${BASE_CLASS}-container`}>
        <Fragment>
          {(() => {
            switch (persistReducer.activeTab) {
              case "LANDING":
                return <Dashboard />;
              case "CATEGORYMANAGEMENT":
                return <CategoryManagement />;
              case "APPROVALSANDREQUESTS":
                return <ApprovalsAndRequests />;
              case "POWERBIDASHBOARD":
                return <PowerBIDashboard />;
              case "REPORTS":
                return <Reports />;
              default:
                dispatch({ type: ACTIVE_TAB, payload: "LANDING" });
                return <div>Invalid address</div>;
            }
          })()}
        </Fragment>
      </div>
    </div>
  );
};
