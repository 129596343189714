const COLORSlIST: string[] = [
  "#61BD4C",
  "#7AD3D3",
  // "#EAEAA8",
  "#00292E",
  "#002C46",
  "#004F59",
  "#005072",
  "#005A80",
  "#007680",
  "#0097A9",
  "#00ABAB",
  "#02371D",
  "#26890D",
  "#29AA62",
  "#29B2E5",
  "#2C4260",
  "#3E568D",
  "#549A78",
  "#6FC2B4",
  "#71150F",
  "#7ACA9E",
  "#9DD4CF",
  "#9DD690",
  "#B46A00",
  "#B4C500",
  "#BFC076",
  "#C29C00",
  "#C4D600",
  "#CDDD29",
  "#D3E144",
  "#DA291C",
  // "#DDEFE8",
  "#ED8B00",
  "#FFCD00",
];
export default COLORSlIST;

export const CAT_COLORS_LIST: Record<string, string> = {
  NEUTRAL: "#00A3E0",
  PRODUCTIVE: "#43B02A",
  UNPRODUCTIVE: "#ED8B00",
  UNCATEGORISED: "#63666A",
};
