import { appsClient } from "serviceClient";
type AppRecord = Record<string, string>;

const getAll = (params?: Record<string, any>) => {
  return appsClient.get<Array<AppRecord>>(
    "/app?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  );
};

const get = (id: any) => {
  return appsClient.get<IApp>(`/app/${id}`);
};

const create = (data: IApp) => {
  return appsClient.post<IApp>("/app", data);
};

const update = (id: any, data: IApp) => {
  return appsClient.put<any>(`/app/${id}`, data);
};

const AppService = {
  getAll,
  get,
  create,
  update,
};

export default AppService;
