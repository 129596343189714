import { ChangeEvent, FC } from "react";
import "./Checkbox.scss";

interface CheckboxInterface {
  className?: string;
  label?: string | any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  testId?: string;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxInterface> = ({
  className,
  label,
  value = false,
  onChange,
  disabled,
  testId,
}) => {
  return (
    <label
      className={`checkbox-container dds-label ${className}${
        disabled ? "" : " cursor"
      }`}
    >
      {label ? (
        <span className={`fs-16 ${disabled && "disabled"}`}>{label}</span>
      ) : (
        <>&nbsp;</>
      )}
      <input
        data-testid={testId}
        className={`${disabled ? "" : " cursor"}`}
        checked={value}
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
      />
      <span className={`checkmark ${disabled && "disabled"}`} />
    </label>
  );
};
