import { FC, useContext, useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  SearchBox,
  SortIcon,
  Pagination,
  Tooltip,
} from "global";
import "./ChangeLogTabel.scss";
import { DateFormatt } from "global/utils/DateUtil";
import { Button, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { approvalStatusEnum, StatusObj } from "global/constants/Enums";
import { FilterComp } from "../filter/Filter";
import { PositionState, InvokeType } from "global/components/tooltip/typings";
import { AllRequestedCompleted, NoDataFoundIcon } from "global";
interface IChangeLogTabelProps {
  active: Record<string, any>;
  data: any[];
  hasApproveEntitled: boolean;
  updateChangeReq: (data: any) => void;
}
const BASE_CLASS = "ChangeLogTabel";
const itemsPerPage = 10;
const ChangeLogTabel: FC<IChangeLogTabelProps> = ({
  active,
  data,
  hasApproveEntitled,
  updateChangeReq,
}) => {
  const { themeObjState } = useContext(ThemeContext);
  const [isSortAsc, setSort] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState<any[]>([]);
  const [activeStatus, setActiveStatus] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setSearchText("");
    setActiveStatus(active.status);
  }, [active]);

  useEffect(() => {
    searchApps(searchText);
  }, [data, activeStatus]);

  const searchApps = (searchVal: string = searchText) => {
    let arrFiltered = [];
    if (searchVal.length > 0) {
      arrFiltered = data.filter((item: any) =>
        item?.appName?.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else {
      arrFiltered = data;
    }

    setCurrentPage(1);
    setFilterData(
      arrFiltered.filter((item) => activeStatus.includes(item.ApprovalStatus))
    );
  };

  const isStatusClmn = (): boolean => {
    return (
      (active.value !== "history" && !hasApproveEntitled) ||
      active.value === "history"
    );
  };

  const isActionClmn = (): boolean => {
    return !isStatusClmn();
  };

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header dds-h5`}>{active.label}</div>
      {data.length > 0 ? (
        <>
          <div className={`${BASE_CLASS}-subHeader`}>
            <SearchBox
              className={`${BASE_CLASS}-searchbox`}
              placeholder="Search text"
              style={{ width: "350px" }}
              value={searchText}
              onChange={(val: string) => {
                searchApps(val);
                setSearchText(val);
              }}
              onClear={() => {
                setSearchText("");
                searchApps("");
              }}
              onClickEnter={() => searchApps()}
            />
          </div>
          <Grid
            verticalSpacing={"0"}
            className={`${BASE_CLASS}-title-row dds-body`}
          >
            <GridItem xs={2} className={`${BASE_CLASS}-title-row-col `}>
              S.No.
            </GridItem>
            <GridItem xs={6} className={`${BASE_CLASS}-title-row-col `}>
              URL/app name
            </GridItem>
            {hasApproveEntitled && (
              <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
                Requested by
              </GridItem>
            )}
            <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
              Date of request
              <img
                onClick={() => setSort(!isSortAsc)}
                src={SortIcon}
                alt="header"
                className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
                  isSortAsc ? "ASC" : "DESC"
                }`}
              />
            </GridItem>
            <GridItem xs={4} className={`${BASE_CLASS}-title-row-col `}>
              From
            </GridItem>
            <GridItem xs={4} className={`${BASE_CLASS}-title-row-col `}>
              To
            </GridItem>
            {isActionClmn() && (
              <>
                <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
                  Function/ department/ sub-department
                </GridItem>
                <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
                  Action
                </GridItem>
              </>
            )}
            {isStatusClmn() && (
              <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
                Status
                {active.value === "history" && (
                  <FilterComp
                    status={active.status.map((item: string) => ({
                      label: item,
                      value: item,
                      selected: activeStatus.includes(item),
                    }))}
                    setFilters={(data: string[]) => {
                      setActiveStatus(data);
                    }}
                  />
                )}
              </GridItem>
            )}
            {active.value === "history" && (
              <GridItem xs={5} className={`${BASE_CLASS}-title-row-col `}>
                Action taken by
              </GridItem>
            )}
          </Grid>
          {filterData.length > 0 ? (
            <>
              {filterData
                .sort((a: any, b: any) => {
                  if (isSortAsc) {
                    return Date.parse(a.created) - Date.parse(b.created);
                  } else {
                    return Date.parse(b.created) - Date.parse(a.created);
                  }
                })
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item: any, i) => (
                  <Grid
                    key={item.id}
                    verticalSpacing={"0"}
                    className={`${BASE_CLASS}-body-row dds-body`}
                  >
                    <GridItem xs={2} className={`${BASE_CLASS}-body-row-col `}>
                      {(currentPage - 1) * itemsPerPage + i + 1}
                    </GridItem>
                    <GridItem xs={6} className={`${BASE_CLASS}-body-row-col `}>
                      <Tooltip
                        toolTipText={item.appName}
                        tooltipPosition={PositionState.bottom}
                        tooltipInvokeType={InvokeType.hover}
                        tooltipWidth={"max-content"}
                        tooltipMaxWidth={"20vw"}
                      >
                        <div className="ellipsis2">{item.appName}</div>
                      </Tooltip>
                    </GridItem>
                    {hasApproveEntitled && (
                      <GridItem
                        xs={5}
                        className={`${BASE_CLASS}-body-row-col `}
                      >
                        {item.userEmail.split("@")[0]}
                      </GridItem>
                    )}
                    <GridItem xs={5} className={`${BASE_CLASS}-body-row-col `}>
                      {DateFormatt(item.created)}
                    </GridItem>
                    <GridItem xs={4} className={`${BASE_CLASS}-body-row-col `}>
                      <Tooltip
                        toolTipText={item.fromCatName}
                        tooltipPosition={PositionState.bottom}
                        tooltipInvokeType={InvokeType.hover}
                        tooltipWidth={"max-content"}
                        tooltipMaxWidth={"20vw"}
                      >
                        <div className="ellipsis2">{item.fromCatName}</div>
                      </Tooltip>
                    </GridItem>
                    <GridItem xs={4} className={`${BASE_CLASS}-body-row-col `}>
                      <Tooltip
                        toolTipText={item.toCatName}
                        tooltipPosition={PositionState.bottom}
                        tooltipInvokeType={InvokeType.hover}
                        tooltipWidth={"max-content"}
                        tooltipMaxWidth={"20vw"}
                      >
                        <div className="ellipsis2">{item.toCatName}</div>
                      </Tooltip>
                    </GridItem>

                    {isActionClmn() && (
                      <>
                        <GridItem
                          xs={5}
                          className={`${BASE_CLASS}-body-row-col `}
                        >
                          <Tooltip
                            toolTipText={`${item.functionalArea} - ${
                              item.department
                            }${
                              item.subDepartment
                                ? ` - ${item.subDepartment}`
                                : ""
                            }`}
                            tooltipPosition={PositionState.bottom}
                            tooltipInvokeType={InvokeType.hover}
                            tooltipWidth={"max-content"}
                            tooltipMaxWidth={"20vw"}
                          >
                            <div className="ellipsis2">
                              {`${item.functionalArea} - ${item.department}${
                                item.subDepartment
                                  ? ` - ${item.subDepartment}`
                                  : ""
                              }`}
                            </div>
                          </Tooltip>
                        </GridItem>
                        <GridItem
                          xs={5}
                          className={`${BASE_CLASS}-body-row-col `}
                        >
                          <div className={`${BASE_CLASS}-action`}>
                            <Button
                              size={Size.m}
                              kind={Kind.primary}
                              theme={themeObjState}
                              label={"Approve"}
                              onClick={() => {
                                updateChangeReq({
                                  ...item,
                                  prevStatus: item?.ApprovalStatus,
                                  ApprovalStatus: approvalStatusEnum.APPROVED,
                                });
                              }}
                            />
                            <Button
                              size={Size.m}
                              kind={Kind.primary}
                              className={`${BASE_CLASS}-action-reject`}
                              theme={themeObjState}
                              label={"Reject"}
                              onClick={() => {
                                updateChangeReq({
                                  ...item,
                                  prevStatus: item?.ApprovalStatus,
                                  ApprovalStatus: approvalStatusEnum.REJECTED,
                                });
                              }}
                            />
                          </div>
                        </GridItem>
                      </>
                    )}
                    {isStatusClmn() && (
                      <GridItem
                        xs={5}
                        className={`${BASE_CLASS}-body-row-col `}
                      >
                        <div className={`${BASE_CLASS}-${item.ApprovalStatus}`}>
                          {StatusObj[item.ApprovalStatus]}
                        </div>
                      </GridItem>
                    )}
                    {active.value === "history" && (
                      <GridItem
                        xs={5}
                        className={`${BASE_CLASS}-body-row-col `}
                      >
                        {item.actionTakenEmail == null
                          ? "-"
                          : item.actionTakenEmail.split("@")[0]}
                      </GridItem>
                    )}
                  </Grid>
                ))}
            </>
          ) : (
            <div className={`${BASE_CLASS}-nosearchdata`}>
              <img
                src={NoDataFoundIcon}
                className={`${BASE_CLASS}-nosearchdata-img`}
              />
            </div>
          )}
          <div className={`${BASE_CLASS}-pagination`}>
            <Pagination
              currentPage={currentPage || 1}
              lastPage={Math.ceil(filterData?.length / itemsPerPage)}
              setCurrentPage={(item) => setCurrentPage(item)}
            />
          </div>
        </>
      ) : (
        <div className={`${BASE_CLASS}-nosearchdata emptyScreen`}>
          <img
            src={AllRequestedCompleted}
            className={`${BASE_CLASS}-nosearchdata-img`}
            alt="no-search-data"
          />
          {active.value === "history" ? (
            <p>No records available.</p>
          ) : (
            <p>No requests to act upon.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ChangeLogTabel;
