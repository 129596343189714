enum ColumnsMap {
  "officeHours" = "Office hours",
  "billableHours" = "Billable hours",
  "email" = "Email",
  "name" = "Name",
  "reportees" = "Reportees",
  "officeLocation" = "Office location",
  "functionalArea" = "Functional area",
  "department" = "Department",
  "subDepartment" = "Sub-department",
  "manager" = "Manager",
  "jobTitle" = "Job title",
  "designationCode" = "Designation code",
  "designationDesc" = "Designation description",
  "rm" = "Reporting manager",
  "partner" = "Partner",
  "gradeCode" = "Grade code",
  "gradeDesc" = "Grade description",
  "employeeId" = "Employee ID",
  "doj" = "Date of joining",
  "employeeStatus" = "Current employee status",
  "skillGroup" = "Skill group",
  "cecRole" = "CEC role",
  "cecRoleDesc" = "CEC role description",
  "entitlements" = "Entitlements",
}

export default ColumnsMap;
