import { CSSProperties, FC } from "react";
import { CloseIconSvg, SearchIcon } from "global";
import "./SearchBox.css";

interface SearchBoxInterface {
  className?: string;
  placeholder: string;
  onChange(newValue: string): void;
  value?: string;
  style?: CSSProperties;
  onClear?(): void;
  onClickEnter?(): void;
}

export const SearchBox: FC<SearchBoxInterface> = ({
  className = "",
  placeholder,
  onChange,
  value = "",
  onClear,
  onClickEnter,
}) => {
  return (
    <div style={{ width: "350px" }} className={`searchbox-main ${className}`}>
      <img
        //className="dropdown-chips-chip-cancel"
        style={{ paddingLeft: "8px" }}
        src={SearchIcon}
        alt="image"
      />
      <input
        className="custom-input"
        style={{ width: "320px" }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e: any) =>
          e.keyCode === 13 && onClickEnter && onClickEnter()
        }
      />
      {value.length > 0 && (
        <CloseIconSvg
          className="close-action-item"
          stroke={"#808080"}
          onClick={() => {
            onChange("");
            onClear && onClear();
          }}
        ></CloseIconSvg>
      )}
    </div>
  );
};
