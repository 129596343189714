import { FC, useMemo, useState } from "react";
import {
  CloseWhiteIconSvg,
  closeIconBlack,
  ErrorMsgIcon,
  SuccessIconSvg,
  WarningIcoSvg,
  InfoIcon,
} from "../../index";
import "./NotifyToast.scss";

interface NotifyToastInterface {
  type: string;
  message: string | any;
}

export const NotifyToast: FC<NotifyToastInterface> = ({ type, message }) => {
  const [show, setShow] = useState(true);
  // const dispatch = useDispatch();

  const color: any = useMemo(
    () => ({
      SUCCESS: {
        svg: SuccessIconSvg,
        close: CloseWhiteIconSvg,
      },
      ERROR: {
        svg: ErrorMsgIcon,
        close: CloseWhiteIconSvg,
      },
      WARNING: {
        svg: WarningIcoSvg,
        close: closeIconBlack,
      },
      INFO: {
        svg: InfoIcon,
        close: closeIconBlack,
      },
    }),
    []
  );

  const onClickClose = () => {
    setShow(false);
    //below timeout can be removed if find any regularities this is to get close animation done
    // setTimeout(() => {
    //   dispatch({
    //     type: CALL_NOTIFY,
    //     payload: {
    //       type: 'DEFAULT',
    //       msg: '',
    //       timeout: 3000
    //     }
    //   });
    // }, 1000);
  };

  // useEffect(() => {
  //   if (notify.type in color) {
  //     setShow(true);
  //     setTimeout(() => {
  //       onClickClose();
  //     }, notify.timeout);
  //   } else {
  //     setShow(false);
  //   }
  // }, [notify.type, color]);

  if (!(type in color)) {
    return null;
  }

  return (
    <>
      {show && (
        <div
          key={message}
          className={`notify-toast-main`}
          style={{ bottom: show ? "5px" : "-75px" }}
        >
          <div className={`--msgbox --${type.toLowerCase()}`}>
            <div className="--spanblock">
              <img
                className="--type"
                src={color[type].svg}
                alt={type.toLowerCase()}
              />
              <span className="--msg dds-tost">{message}</span>
            </div>
            <img
              className="--close"
              onClick={onClickClose}
              src={color[type].close}
              alt="close"
            />
          </div>
        </div>
      )}
    </>
  );
};
