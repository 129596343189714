import {
  PopUpModel,
  Notify,
  BlockScreenMsg,
  Dropdown,
  Tooltip,
  PlusIcon,
  ExportIcon,
  InfoIcon,
  NoFilterSelectedIcon,
} from "global";
import React, { useState, useContext } from "react";
import AppsPage from "./components/appsPage/AppsPage";
import CategoryService from "services/CategoryService";
import AppService from "services/AppService";
import "./CategoryManagement.scss";
import {
  InputBox,
  validateDesc,
  validateRequired,
} from "global/components/inputBox/InputBox";
import {
  Kind,
  Size,
  TreeView,
  Button,
  nodeObjectType,
} from "@usitsdasdesign/dds-react";
import { useDispatch, useSelector } from "react-redux";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { InvokeType, PositionState } from "global/components/tooltip/typings";
import { ThemeContext } from "theme/themeContext";
import noCategoriesIcon from "global/img/Nocategoriesfound.svg";
import Filter from "./categoryFilter/CategoryFilter";
import { RootState } from "app/store";
import { roleEnum } from "global/constants/Enums";

const initFilters: ICategoryFilters = {
  functionalArea: "",
  department: "",
  subDepartment: "",
};
type CategoryRecord = Record<string, any>;
const BASE_CLASS = "categoryManagement";
const CategoryManagement: React.FC = () => {
  const { themeObjState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const LoginUserData = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );
  const [categories, setCategories] = useState<CategoryRecord[]>([]);
  const [expandedCat, setExpandedCat] = useState<string[]>([]);
  const [selCategory, setSelCategory] = useState<CategoryRecord>();
  const [removeCat, setRemoveCat] = useState<
    | { id: string; name: string; apps: any[]; parent?: Record<string, any> }
    | undefined
  >();
  const [isModifyCat, setIsModifyCat] = useState(false);
  const [modifyCat, setModifyCat] = useState<Record<string, any> | undefined>();
  const [isModifySubCat, setIsModifySubCat] = useState(false);
  const [modifySubCat, setModifySubCat] = useState<
    Record<string, any> | undefined
  >();
  const [isFormDirty, setIsFormDirty] = useState(false);
  // const [formValidity, setFormValidity] = useState({
  //   "Category Name": false,
  // });
  // const [subCatFormValidity, setSubCatFormValidity] = useState({
  //   "Subcategory Name": false,
  // });
  const [addAppsSubCat, setAddAppsSubCat] = useState<
    Record<string, any> | undefined
  >();

  const [formDataObj, setFormDataObj] = useState<
    Record<string, any> | undefined
  >();
  const [filters, setFilters] = useState<ICategoryFilters | any>({
    ...initFilters,
  });

  const getCategories = async (
    filtersData: Record<string, any>,
    isDel?: boolean
  ) => {
    try {
      let params: Record<string, string> = {};

      Object.keys(filtersData).forEach((key) => {
        if (filtersData[key] && filtersData[key] !== "*") {
          params = { ...params, [key]: btoa(filtersData[key]) };
        }
      });
      if (
        LoginUserData.role === roleEnum.MASTER ||
        Object.keys(params).length > 0
      ) {
        dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
        const res: any = await CategoryService.getAll(params);
        const resCategories: CategoryRecord[] = res.data["data"];
        // resCategories.sort((a, b) => a.name - b.name);
        const uncategorizedapps: any = await AppService.getAll({
          ...params,
          uncategorizedapps: "true",
        });
        const tempCat = [
          ...resCategories,
          {
            id: "uncategorizedapps",
            name: "Uncategorised apps",
            className: `${BASE_CLASS}-uncategorizedapps `,
            apps: uncategorizedapps.data["data"].map((app: any) => ({
              name: app.name,
              title: app.name,
              id: app.id,
            })),
          },
        ];
        if (resCategories.length) {
          if (isDel || !selCategory) {
            resCategories[0].subcategories.length
              ? setSelCategory(resCategories[0].subcategories[0])
              : setSelCategory(resCategories[0]);
          }
          if (
            expandedCat.length === 0 ||
            !expandedCat.includes(resCategories[0].id)
          ) {
            setExpandedCat([...expandedCat, resCategories[0].id]);
          }
        }
        setCategories(tempCat);
      } else {
        setCategories([]);
      }
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (err: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: err.response?.data.message || `Error while getting all apps`,
          timeout: 3000,
        },
      });
    }
  };
  const getFormattedApps = (data: any, id: string) => {
    const returnData = data
      .map((item: any) => {
        if (item.id === id) {
          return item;
        }
        if (item.subcategories) {
          return getFormattedApps(item.subcategories, id)[0];
        }
      })
      .filter((item: any) => item);

    return returnData;
  };

  const formattCategory = (
    data: CategoryRecord[],
    type: "cat" | "sub-cat"
  ): nodeObjectType[] => {
    return data.map((item) => {
      let classnames = `${item?.className ? item?.className : ""}${
        item.id === selCategory?.id && item?.id !== "uncategorizedapps"
          ? `${BASE_CLASS}-selected`
          : `${BASE_CLASS}-normal`
      }`;
      if (
        item?.id === "uncategorizedapps" &&
        selCategory?.id === "uncategorizedapps"
      ) {
        classnames = `${classnames} ${BASE_CLASS}-uncategorizedapps-selected`;
      } else {
        if (type === "cat") {
          classnames = `${classnames} ${BASE_CLASS}-category`;
          if (
            item.subcategories?.some(
              (sel: CategoryRecord) => sel.id === selCategory?.id
            )
          ) {
            classnames = `${classnames} ${BASE_CLASS}-selected-category`;
          }
        } else {
          classnames = `${classnames} ${BASE_CLASS}-category-sub`;
        }
      }

      return {
        className: classnames,
        id: item.id,
        label: item.name,
        isExpanded: expandedCat.includes(item.id),
        children: formattCategory(item?.subcategories || [], "sub-cat"),
      };
    });
  };

  const handleAddCategory = () => {
    setIsModifyCat(true);
    setModifyCat(undefined);
    setFormDataObj(undefined);
  };
  const removeCatfunc = async () => {
    if (!removeCat) return;
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await CategoryService.remove(removeCat.id).then(() => {
        dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
        let deletemsg;
        removeCat?.parent
          ? (deletemsg = `Subcategory ${removeCat.name} has been successfully removed. `)
          : (deletemsg = `'${removeCat.name}' category have been successfully removed.`);
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "SUCCESS",
            msg: deletemsg,
            timeout: 3000,
          },
        });
        getCategories(filters, true);
      });
    } catch (error: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg:
            error.response?.data.message ||
            `You are unauthorized to perform this action`,
          timeout: 3000,
        },
      });
    }
  };

  const createOrUpdateCategory = async (
    payload: Record<string, any>,
    modeType: "cat" | "sub-cat",
    mode: "add" | "edit"
  ) => {
    if (!formDataObj) return;
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      let resData: any;
      if (addAppsSubCat && filters.functionalArea !== "") {
        let tempFilters = {};
        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            tempFilters = { ...tempFilters, [key]: filters[key] };
          }
        });
        await CategoryService.changeReq({
          ...tempFilters,
          appName: formDataObj?.apps.map((app: any) => app.name),
          fromCategory: "uncategorizedapps",
          toCategory: formDataObj?.parent?.id,
          toSubCategory: formDataObj?.id,
        });
      } else if (mode === "add") {
        resData = await CategoryService.create({
          ...payload,
          catType: modeType,
        });
      } else {
        resData = await CategoryService.update(formDataObj.id, payload);
      }

      let successMessage;
      modeType === "cat"
        ? (successMessage = `'${
            formDataObj.name
          }' category has been successfully ${
            mode === "add" ? "added" : "updated"
          }.`)
        : mode === "add"
        ? (successMessage = `'${formDataObj.name}' subcategory have been successfully added.`)
        : addAppsSubCat
        ? (successMessage = `URLs/apps have been successfully added to this subcategory.`)
        : (successMessage = `'${formDataObj.name}' subcategory have been successfully updated.`);
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "SUCCESS",
          msg: successMessage,
          timeout: 3000,
        },
      });
      mode === "add" && setSelCategory(resData.data.data);
      setFormDataObj(undefined);
      setIsFormDirty(false);
      setIsModifyCat(false);
      setIsModifySubCat(false);
      getCategories(filters);
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (err: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      if (err.response?.data?.message) {
        setFormDataObj({
          ...formDataObj,
          error: {
            [modeType === "cat" ? "catName" : "subCatName"]: isMaster
              ? "This name already exists. Please try again."
              : err.response?.data?.message,
            // modeType === "cat"
            //   ? err.response?.data?.message
            //   : err.response?.data?.message?.replace(
            //       "Category",
            //       "Subcategory"
            //     ),
          },
        });
      } else {
        setFormDataObj(undefined);
        setIsFormDirty(false);
        setIsModifyCat(false);
        setIsModifySubCat(false);
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "ERROR",
            msg: `You are unauthorized to perform this action`,
            timeout: 3000,
          },
        });
      }
    }
  };
  const handleInputChange = () => {
    setIsFormDirty(true);
    // if (isModifySubCat)
    //   setSubCatFormValidity((prevState) => ({
    //     ...prevState,
    //     [fieldName]: isValid,
    //   }));
    // else
    //   setFormValidity((prevState) => ({
    //     ...prevState,
    //     [fieldName]: isValid,
    //   }));
  };
  const handleSubCategory = () => {
    let payload: any = {
      name: formDataObj?.name,
      apps: formDataObj?.apps?.map((app: any) => app.id) || [],
    };
    if (addAppsSubCat) {
      payload = {
        apps: formDataObj?.apps.map((app: any) => app.id) || [],
      };
    }
    if (!modifySubCat) {
      payload = { ...payload, parent: formDataObj?.parent };
    }
    createOrUpdateCategory(payload, "sub-cat", modifySubCat ? "edit" : "add");
  };
  const handleCategory = () => {
    createOrUpdateCategory(
      {
        name: formDataObj?.name,
      },
      "cat",
      modifyCat ? "edit" : "add"
    );
  };

  const isMaster = LoginUserData.role === roleEnum.MASTER;
  return (
    <>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}-sidebar`}>
          <div
            className="category-titlebar"
            style={{ justifyContent: "space-between" }}
          >
            <div className="title">Categories</div>
            <Tooltip
              toolTipText={"Create category"}
              tooltipPosition={PositionState.bottom}
              tooltipInvokeType={InvokeType.hover}
              tooltipWidth={"max-content"}
              tooltipIsDisabled={LoginUserData.role === roleEnum.ENTITLED}
            >
              <PlusIcon
                className={`action-item ${
                  LoginUserData.role === roleEnum.ENTITLED && "disabled"
                }`}
                style={{ marginRight: "10px" }}
                stroke={"black"}
                cursor={"pointer"}
                onClick={() => handleAddCategory()}
              />
            </Tooltip>
            <ExportIcon
              className="action-item disabled"
              stroke={"black"}
              cursor={"pointer"}
              // onClick={() => {}}
            />
          </div>
          <div className="divider" style={{ marginBottom: "10px" }}></div>
          <TreeView
            className={`${BASE_CLASS}-sidebar-scroll`}
            size={Size.m}
            theme={themeObjState}
            itemActivated={(item) => setSelCategory(item)}
            itemExpandChanged={(item) => {
              if (item.isExpanded) {
                setExpandedCat([...expandedCat, item.id]);
              } else {
                setExpandedCat([
                  ...expandedCat.filter((cat) => cat !== item.id),
                ]);
              }
            }}
            recursionDeepness={20}
            treeItems={formattCategory(categories, "cat")}
          />
        </div>
        <div className={`${BASE_CLASS}-content`}>
          {categories.length === 0 ? (
            LoginUserData.role === roleEnum.MASTER ||
            (LoginUserData.role === roleEnum.ENTITLED &&
              filters?.functionalArea !== "") ? (
              <div className="empty-categories-page emptyScreen">
                <img src={noCategoriesIcon} alt="No categories found" />
                <h3>No categories found.</h3>
                <p>Create a category to proceed.</p>
                <Button
                  size={Size.m}
                  kind={Kind.primaryLoud}
                  theme={themeObjState}
                  label="Create"
                  onClick={() => handleAddCategory()}
                />
              </div>
            ) : (
              <div className="empty-categories-page emptyScreen">
                <img src={NoFilterSelectedIcon} alt="No filter selected" />
                <h3>No access.</h3>
                <span>Select the required authorisation(s) for category and data access.</span>
              </div>
            )
          ) : (
            <AppsPage
              isMaster={isMaster}
              setCategories={setCategories}
              categories={categories}
              data={getFormattedApps(categories, selCategory?.id)[0]}
              setRemoveCat={setRemoveCat}
              editCat={(data: any) => {
                setIsModifyCat(true);
                setModifyCat(data);
                setFormDataObj(data);
              }}
              filters={filters}
              modifySubCat={(data: any) => {
                setIsModifySubCat(true);
                setModifySubCat(data);
                if (data) {
                  setFormDataObj(data);
                } else {
                  const tempCat = getFormattedApps(
                    categories,
                    selCategory?.id
                  )[0];
                  setFormDataObj({
                    parent: tempCat?.parent || tempCat,
                  });
                }
              }}
              addApps={(data: any) => {
                setIsModifySubCat(true);
                setAddAppsSubCat(data);
                setModifySubCat(data);
                setFormDataObj({ ...data, apps: [] });
              }}
            />
          )}
        </div>
        <Filter
          updateFilters={(data: ICategoryFilters) => {
            setFilters(data);
            getCategories(data);
          }}
          initialFilters={filters}
        />
      </div>
      {isModifyCat && (
        <PopUpModel
          saveTitle={modifyCat ? "Update" : "Create"}
          onClickClose={() => {
            setIsModifyCat(false);
            setModifyCat(undefined);
            setIsFormDirty(false);
          }}
          onSaveClick={handleCategory}
          Title={!modifyCat ? "Create category" : "Edit category name"}
          disabledSave={
            formDataObj?.error?.catName ||
            !(formDataObj?.name?.length && isFormDirty)
          }
        >
          <div className={`${BASE_CLASS}-popup-body`}>
            <InputBox
              value={formDataObj?.name || ""}
              mainClassName="width-100"
              labelText={"Category name"}
              isRequired={true}
              isError={!!formDataObj?.error?.catName}
              errorText={formDataObj?.error?.catName}
              addLabel={true}
              placeholder="Type the name here"
              validators={[validateRequired, validateDesc]}
              onInputChange={handleInputChange}
              onChange={(value: any) =>
                setFormDataObj({
                  ...formDataObj,
                  name: value,
                  error: { ...formDataObj?.error, catName: "" },
                })
              }
              onKeyDown={(e: any) => {
                if (
                  e.keyCode === 13 &&
                  !(
                    formDataObj?.error?.catName ||
                    !(formDataObj?.name?.length && isFormDirty)
                  )
                ) {
                  handleCategory();
                }
              }}
            />
          </div>
        </PopUpModel>
      )}
      {isModifySubCat && (
        <PopUpModel
          saveTitle={addAppsSubCat ? "Add" : !modifySubCat ? "Add" : "Update"}
          onClickClose={() => {
            setIsModifySubCat(false);
            setModifySubCat(undefined);
            setAddAppsSubCat(undefined);
            setIsFormDirty(false);
          }}
          onSaveClick={handleSubCategory}
          Title={
            addAppsSubCat
              ? "Add URLs/apps"
              : !modifySubCat
              ? "Add subcategory"
              : "Edit subcategory name"
          }
          disabledSave={
            formDataObj?.error?.subCatName ||
            !(
              formDataObj?.name?.length &&
              ((addAppsSubCat && formDataObj?.apps?.length > 0) ||
                (!addAppsSubCat && isFormDirty))
            )
          }
        >
          <div className={`${BASE_CLASS}-popup-body`}>
            {!addAppsSubCat && (
              <InputBox
                value={formDataObj?.name || ""}
                mainClassName="width-100"
                labelText={"Subcategory name"}
                isRequired={true}
                disabled={!!addAppsSubCat}
                isError={!!formDataObj?.error?.subCatName}
                errorText={formDataObj?.error?.subCatName}
                addLabel={true}
                placeholder="Type the name here"
                validators={[validateRequired, validateDesc]}
                onInputChange={handleInputChange}
                onChange={(value: any) =>
                  setFormDataObj({
                    ...formDataObj,
                    name: value,
                    error: { ...formDataObj?.error, subCatName: "" },
                  })
                }
                onKeyDown={(e: any) => {
                  if (
                    e.keyCode === 13 &&
                    !(
                      formDataObj?.error?.subCatName ||
                      !(
                        formDataObj?.name?.length &&
                        ((addAppsSubCat && formDataObj?.apps?.length > 0) ||
                          (!addAppsSubCat && isFormDirty))
                      )
                    )
                  ) {
                    handleSubCategory();
                  }
                }}
              />
            )}
            {addAppsSubCat &&
              Object.keys(filters).some((key) => filters[key] !== "") && (
                <div
                  className="dds-label"
                  style={{ display: "flex", gap: "1vh" }}
                >
                  <img src={InfoIcon} />
                  URLs/apps categorisation is specific to the selected filter
                </div>
              )}
            {(!modifySubCat || addAppsSubCat) && (
              <Dropdown
                defaultValue={""}
                labelText={"Add apps"}
                isRequired={!!addAppsSubCat}
                addLabel={true}
                showValue={true}
                showChips={true}
                onchange={(items) =>
                  setFormDataObj({
                    ...formDataObj,
                    apps: items,
                  })
                }
                placeholder={"Choose from the list of uncategorised URLs/apps"}
                options={categories
                  .filter((cat) => cat.id === "uncategorizedapps")[0]
                  .apps.sort((a: any, b: any) => a.name.localeCompare(b.name))
                  .map((app: { name: string }) => ({
                    ...app,
                    label: app.name,
                    value: app.name,
                  }))}
                isSearchable={true}
                isMulti={true}
                controlShouldRenderValue={false}
                isClearable={false}
                dropdownIndicatorStyle={{ display: "none" }}
                containerStyle={{ display: "flex", gap: "2px" }}
                isSearchBox={true}
              />
            )}
          </div>
        </PopUpModel>
      )}

      {removeCat && (
        <PopUpModel
          Title={`Remove ${removeCat?.parent ? "subcategory" : "category"}`}
          onClickClose={() => setRemoveCat(undefined)}
          onSaveClick={async () => {
            await removeCatfunc();
            setRemoveCat(undefined);
          }}
          saveTitle="Remove"
          closeTitle="Cancel"
          saveButtonClass="deleteColor"
        >
          <p style={{ fontSize: "14px" }}>
            {`All the apps in this ${
              removeCat?.parent ? "subcategory" : "category"
            } will be moved to uncategorised section. Are you sure you want to remove this ${
              removeCat?.parent ? "subcategory" : "category"
            }?`}
          </p>
        </PopUpModel>
      )}
      <Notify />
      <BlockScreenMsg />
    </>
  );
};

export default CategoryManagement;
