import {
  BackIcon,
  Grid,
  GridItem,
  NotifyToast,
  SortIcon,
  Tooltip,
  UserIconWarn,
} from "global";
import { useEffect, FC, useState, useContext, Fragment } from "react";
import CategoryService from "services/CategoryService";
import { getEvents } from "services/ReportingService";
import { getAllusers } from "services/orgLevelService";
import "./TeamLevel.scss";
import { roleEnum } from "global/constants/Enums";
import { useDispatch, useSelector } from "react-redux";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { Button, DateRangePicker, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import ProgressBar from "../components/progressBar/ProgressBar";
import COLORSlIST, { CAT_COLORS_LIST } from "global/constants/Colors";
import {
  getEndOfDay,
  getStartOfDay,
  format_HR_MIN_SEC,
} from "global/utils/DateUtil";
import { InvokeType, PositionState } from "global/components/tooltip/typings";
import UserApps from "./UserAppsData";
import { RootState } from "app/store";
import { GLOBAL_USERS } from "home/store/action";
import { debounce } from "global/utils/debounce";

const BASE_CLASS = "teamLevel";
const baseDate = new Date(new Date().setDate(new Date().getDate() - 1));

const TeamLevel: FC = () => {
  const { themeObjState } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );

  const [refresh, setRefresh] = useState<boolean>();
  const [loginUserEvents, setLoginUserEvents] = useState<IUser[]>([]);
  const [directReportees, setDirectReportees] = useState<IUser[]>([]);
  const [modalUser, setModalUser] = useState<IUser>();
  const [selectedUser, setSelectedUser] = useState<IUser>(LoginUserData);
  const [userManagers, setUserManager] = useState<string[]>([]);
  const [legendsData, setLegendsData] = useState<
    { label: string; color: string }[]
  >([]);
  const [viewLength, setViewLength] = useState<number>(10);
  const [isSortAsc, setSort] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState<
    "swipeInOut" | "totalHrs" | "billableHours"
  >("totalHrs");

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: baseDate,
    endDate: baseDate,
  }); // date range for filtering data

  const GlobalUsers: IUser[] = useSelector(
    (state: RootState) => state.homeReducer.globalUsers
  );

  const [categoryNames, setCategoryNames] = useState<string[]>([]);
  const [allCategoriesData, setAllCategories] = useState<any>({});
  const [userList, setUserList] = useState<IUser[]>([...GlobalUsers]);

  let allCategories: any = { ...allCategoriesData };
  let allUsers: IUser[] = [...userList];

  const updateLocalData = debounce(() => {
    setUserList([...allUsers]);
    setAllCategories({ ...allCategories });
  }, 1000);

  useEffect(() => {
    return () => {
      dispatch({
        type: GLOBAL_USERS,
        payload: userList.filter((item) => {
          if (item?.created) {
            delete item?.categoriesData;
            delete item?.apps;
            return item;
          }
        }),
      });
    };
  }, []);

  useEffect(() => {
    onloadData();
  }, [selectedUser, dateRange.startDate]);

  const updateLegendsData = (data: any[]) => {
    const tempLegends = [
      ...legendsData.filter((legend) => legend.label !== "Uncategorized"),
      ...data
        .filter(
          (item: any) =>
            !legendsData.some((legend) => legend.label === item.name)
        )
        .map((item: any, i: number) => ({
          label: item.name,
          color:
            CAT_COLORS_LIST?.[item.name?.toUpperCase()] ||
            COLORSlIST[i % COLORSlIST.length],
        })),
    ];
    setLegendsData([
      ...tempLegends,
      {
        label: "Uncategorized",
        color: COLORSlIST[tempLegends.length % COLORSlIST.length],
      },
    ]);
  };

  /**
   * method to get all managers details for selected user
   * @param selUser selected user
   */
  const loadManagers = async (selUser: any) => {
    try {
      let userIndex = -1;
      const managerList = [
        ...new Set(userManagers.map((item: any) => item.email)),
      ] as string[];

      let emailManager = selUser?.manager;
      const myManagers: any[] = [selUser];
      while (myManagers[0].email) {
        emailManager = myManagers[0].manager;
        if (
          !emailManager ||
          emailManager === undefined ||
          emailManager === myManagers[0].email ||
          managerList.includes(emailManager)
        ) {
          userIndex = userManagers.findIndex(
            (item: any) => item.email === emailManager
          );
          break;
        }
        const userRes = await getUserData(emailManager);
        myManagers.unshift(userRes?.[0]);
      }
      setUserManager((oldmanagers) => [
        ...oldmanagers.splice(0, userIndex + 1),
        ...myManagers,
      ]);
    } catch (error) { }
  };

  const onloadData = async () => {
    try {
      const currentUser = selectedUser;

      getCategoryNames();
      updateLegendsData(categoryNames);

      // get login user events
      getReporteesData(LoginUserData.email, true);

      if (userManagers?.length === 0) {
        await loadManagers(currentUser);
      } else {
        const userIndex = userManagers.findIndex(
          (item: any) => item.email === currentUser.email
        );
        if (userIndex < 0) {
          await loadManagers(currentUser);
        } else {
          setUserManager((oldmanagers) => [
            ...oldmanagers.splice(0, userIndex + 1),
          ]);
        }
      }

      // fetch reportees data if exists
      if (currentUser?.reportees && currentUser.reportees.length > 0) {
        let tempReporteesArr = [];

        if (currentUser?.reportees.includes(LoginUserData.email)) {
          tempReporteesArr = currentUser?.reportees.filter((user) => user !== LoginUserData.email);
        } else {
          tempReporteesArr = currentUser?.reportees;
        }

        const emailList = tempReporteesArr.join();
        getReporteesData(emailList);
      } else {
        setDirectReportees([]);
      }

      if (modalUser) {
        handleProgressbarClick(modalUser);
      }

      updateLocalData();
    } catch (error) {
      console.log("Failed to load data", error)
    }
  };

  /**
   * method to get user reportees data along with timespent data. does not give apps data 
   * @param emailList comma separated email list
   * @param isLoginUser if fetching data for login user
   * @returns reportees data with timespent details in seconds
   */
  const getReporteesData = async (emailList: string, isLoginUser: boolean = false) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

      const params = {
        emailList: emailList,
        startDate: getStartOfDay(dateRange.startDate).toString(),
        endDate: getEndOfDay(dateRange.endDate).toString(),
      }

      await getAllusers(params).then((res) => {
        const resData = res.data.data;

        // convert timespent data from milliseconds to seconds
        // & calculate totalHrs (in seconds) by adding all values in item.timespent
        const finalDataInSeconds = resData.map((item: any) => ({
          ...item,
          timespent: Object.fromEntries(
            Object.entries(item?.timespent).map(
              ([key, value]: [any, any]) => [key, value / 1000]
            )),
        }))
          .map((item: any) => ({
            ...item,
            totalHrs: Object.values(item?.timespent).reduce(
              (acc: any, val: any) => acc + val, 0
            )
          }));

        if (isLoginUser) {
          setLoginUserEvents(finalDataInSeconds);
        } else {
          setDirectReportees(finalDataInSeconds);
        }
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      console.log("Failed to load data", error);
    }
  };

  /**
   * method to get user events and formatt it accordingly
   * @param email user email
   * @returns user apps with timespent details
   */
  const preprocessing = async (userData: IUser) => {
    try {
      if (!userData?.email) return;
      const events = (await getEvents({
        username: userData?.email?.split("@")[0],
        startDate: getStartOfDay(dateRange.startDate).toString(),
        endDate: getEndOfDay(dateRange.endDate).toString(),
      })) as any;
      const cat: Record<string, number> = {};
      events.data.data.forEach((item: any) => {
        if (cat[item?.data?.app]) {
          cat[item.data.app] += Math.floor(item.duration / 1000);
        } else {
          cat[item.data.app] = Math.floor(item.duration / 1000);
        }
      });

      let timespent: any = { Uncategorized: 0 };
      userData?.categoriesData.forEach((item: { name: string }) => {
        timespent = { ...timespent, [item.name]: 0 };
      });

      let totalHrs = 0;
      const data: any = Object.keys(cat).map((key) => {
        const catName =
          userData?.apps?.filter(
            (ele: { appName: string }) => ele.appName === key
          )?.[0]?.category || "Uncategorized";

        totalHrs += cat[key];
        if (timespent[catName]) {
          timespent[catName] += cat[key];
        } else {
          timespent[catName] = cat[key];
        }
        return {
          appName: key,
          duration: cat[key],
          category: catName,
        };
      });

      return {
        ...userData,
        appsData: data,
        category: cat,
        timespent,
        totalHrs,
      };
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * method to get user apps data
   * @param item user for which app data is required
   * @returns user apps with timespent details
   */
  const handleProgressbarClick = async (item: IUser) => {
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

    let newUserData: any = {};
    const key = getCatKey(item);

    if (allCategories?.[key]) {
      newUserData = { ...item, ...allCategories[key] };
    } else {
      const { categoriesData, apps } = await getCategoriesData(item);
      newUserData = {
        ...item,
        categoriesData,
        apps,
      };
    }

    const processedUserData = await preprocessing(newUserData);

    setModalUser(processedUserData);
    dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
  };

  /**
   * method to get all category names
   * @returns all category names
   */
  const getCategoryNames = async () => {
    try {
      const params = {
        selection: "name",
      };
      const resData: any = await CategoryService.getAll(params);
      setCategoryNames(resData.data.data);
      updateLegendsData(resData.data.data);
    } catch (error) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Failed to load categories`,
          timeout: 3000,
        },
      });
    }
  };

  const getCatKey = (user: IUser): string =>
    `${user.functionalArea}-${user.department}-${user.subDepartment}`;

  /**
   * method to get user specific category
   * @param userData user for which category data is required
   * @returns user specefic category
   */
  const getCategoriesData = async (userData: IUser) => {
    try {
      const key = getCatKey(userData);
      let params: Record<string, string> = {
        functionalArea: btoa(userData?.functionalArea || ""),
        department: btoa(userData?.department || ""),
      };
      if (userData?.subDepartment) {
        params = {
          ...params,
          subDepartment: btoa(userData?.subDepartment || ""),
        };
      }
      const resData: Record<string, any> = await CategoryService.getAll(params);
      const categoriesData = resData.data.data;
      await updateLegendsData(categoriesData);
      const resultData = {
        categoriesData,
        apps: categoriesData
          .map((item: Record<string, any>) => {
            return item.subcategories
              .map((ele: IUser) => ele.apps)
              .flat(1)
              .map((ele: { name: string }) => ({
                appName: ele.name,
                category: item.name,
              }));
          })
          .flat(1),
      };
      // assign fetched category to loacal variable
      allCategories = {
        ...allCategories,
        [key]: resultData,
      };
      setAllCategories({
        ...allCategories,
        [key]: resultData,
      });

      return resultData;
    } catch (error) {
      await updateLegendsData([]);
      return { categoriesData: [], apps: undefined };
    }
  };

  /**
   * method to get user details
   * @param email User email with commma separeted
   * @param isUserDetail condition for if billable, swipeInAndOut data
   * @returns selected users
   */
  const getUserData = async (email: string, isUserDetail?: boolean) => {
    if (!email) return;
    try {
      let tempUser: any[] = [];
      //  condition for if billable, swipeInAndOut data
      if (isUserDetail) {
        const startDate = getStartOfDay(dateRange.startDate).toString();
        const endDate = getEndOfDay(dateRange.endDate).toString();

        // check whether uiser data with swipe in and billable data exist with same start and end date
        //  if exist return same
        const tempEmails = email.split(",").filter((userEmail: string) => {
          if (
            allUsers.some(
              (user) =>
                user.email === userEmail &&
                user?.startDate === startDate &&
                user?.endDate === endDate
            )
          ) {
            tempUser = [
              ...tempUser,
              allUsers.filter((user) => user.email === userEmail)[0],
            ];
          } else {
            return userEmail;
          }
        });

        // bulk get call, if user data does not exist in previously feteched data,
        //  get data from api call
        if (tempEmails.length > 0) {
          const params = {
            email: tempEmails.join(),
            startDate,
            endDate,
            billableHours: true,
            swipeInOut: true,
          };
          const tempData = await getUserApiCall(params);
          tempUser = [
            ...tempUser,
            ...tempData.map((item: any) => ({
              ...item,
              startDate: getStartOfDay(dateRange.startDate).toString(),
              endDate: getEndOfDay(dateRange.endDate).toString(),
            })),
          ];
        }
      } else {
        // if User exist return same else fetch it using api call
        if (allUsers.some((user) => user.email === email)) {
          tempUser = allUsers.filter((user) => user.email === email);
        } else {
          tempUser = await getUserApiCall({ email });
        }
      }
      if (isUserDetail) {
        const newUserData: IUser[] = [];
        for (let index = 0; index < tempUser.length; index++) {
          const user = tempUser[index];
          const key = getCatKey(user);
          if (allCategories?.[key]) {
            newUserData.push({ ...user, ...allCategories[key] });
          } else {
            const { categoriesData, apps } = await getCategoriesData(user);
            newUserData.push({
              ...user,
              categoriesData,
              apps,
            });
          }
        }
        tempUser = [...newUserData];
      }

      //  assign users to local variable
      allUsers = [
        ...allUsers.filter(
          (item) => !tempUser.some((user) => user.id === item.id)
        ),
        ...tempUser,
      ];
      return tempUser;
    } catch (error) {}
  };

  /**
   * Method to get api call for users
   * @param params contains email with comma seprated and additional params if req
   * @returns users array
   */
  const getUserApiCall = async (params: Record<string, any>) => {
    return await getAllusers(params).then((resData) =>
      resData.data.data[0] && resData.data.data[0]?.email
        ? resData.data.data
        : [
            {
              id: params.email,
              email: params.email,
              name: params.email,
              reportees: [],
              jobTitle: "",
            },
          ]
    );
  };

  /**
   * method to sort the reportees
   * @param isAll if it is req login events
   * @returns sorted selected user reportee in ass order
   */
  const getReportees = (isAll?: boolean) => {
    const tempData = directReportees.sort(
      (a: any, b: any) => b[sortKey] - a[sortKey]
    );
    let resultData = [];
    if (isAll) {
      resultData = [...loginUserEvents, ...tempData];
    } else {
      resultData = [...tempData];
    }
    return resultData;
  };

  /**
   * method to verify if user has access
   * @param item User data
   * @returns boolean type if true allow to click
   */
  const isAllowUserClick = (item: IUser): boolean => {
    if (
      (item.email !== LoginUserData.email &&
        item?.reportees &&
        item?.reportees?.length > 0) ||
      (item.email === LoginUserData.email && item.email !== selectedUser.email)
    ) {
      return true;
    }
    return false;
  };

  /**
   * method to verify if user has access to view manager details
   * @param item User data
   * @returns boolean type if true allow to click
   */
  const isAllowManagerCLick = (item: IUser): boolean => {
    if (
      (LoginUserData.role === roleEnum.MASTER &&
        selectedUser.email !== item.email) ||
      (selectedUser.email !== item.email && LoginUserData.email === item.email)
    ) {
      return true;
    }
    return false;
  };

  const getHeaderHtml = () => {
    return (
      <Grid
        columns={33}
        className={`${BASE_CLASS}-title-row dds-label `}
        style={{ padding: "0 1vw" }}
      >
        <GridItem xs={7} className={`${BASE_CLASS}-title-row-col`}>
          Team member
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3.2} className={`${BASE_CLASS}-title-row-col`}>
          Clocked in time
          <img
            onClick={() => {
              setSort(!isSortAsc);
              setSortKey("swipeInOut");
            }}
            src={SortIcon}
            alt="header"
            className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
              isSortAsc && sortKey === "swipeInOut" ? "ASC" : "DESC"
            }`}
          />
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3} className={`${BASE_CLASS}-title-row-col`}>
          Billable hours
          <img
            onClick={() => {
              setSort(!isSortAsc);
              setSortKey("billableHours");
            }}
            src={SortIcon}
            alt="header"
            className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
              isSortAsc && sortKey === "billableHours" ? "ASC" : "DESC"
            }`}
          />
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3} className={`${BASE_CLASS}-title-row-col`}>
          Total hours
          <img
            onClick={() => {
              setSort(!isSortAsc);
              setSortKey("totalHrs");
            }}
            src={SortIcon}
            alt="header"
            className={`cursor ${BASE_CLASS}-title-row-col-icon-sort-${
              isSortAsc && sortKey === "totalHrs" ? "ASC" : "DESC"
            }`}
          />
        </GridItem>
        <GridItem xs={17} className={`${BASE_CLASS}-title-row-col`} />
      </Grid>
    );
  };

  const getProgressBar = (item: IUser, isCLickable: boolean) => (
    // item?.apps ? (
    <ProgressBar
      isCLickable={isCLickable}
      data={item?.timespent || {}}
      totalHrs={
        // item?.totalHrs || 0
        +Math.max.apply(
          Math,
          getReportees(true)?.map((hrs: IUser) => hrs?.totalHrs || 0)
        )
      }
      legends={legendsData}
    />
  );
  // ) : (
  //   <></>
  // );

  const getUserDetailHtml = (item: IUser, i: number, isCLickable = true) => (
    <Fragment key={item.id}>
      <Grid
        columns={33}
        className={`${BASE_CLASS}-body-row dds-body`}
        style={{ padding: "0 1vw" }}
      >
        <GridItem xs={7} className={`${BASE_CLASS}-body-row-col `}>
          <Tooltip
            toolTipText={
              <>
                {checkNameTooltip(`overflowingText-reportee-${item.email}`) && (
                  <div>{item.name}</div>
                )}
                {checkEmailTooltip(
                  `overflowingText-reportee-${item.email}`
                ) && <div>{item.email}</div>}
                {checkJobTitleTooltip(
                  `overflowingText-reportee-${item.email}`
                ) && <div>{item.jobTitle}</div>}
              </>
            }
            tooltipPosition={PositionState.right}
            tooltipInvokeType={InvokeType.hover}
            tooltipWidth={"max-content"}
            tooltipIsDisabled={checkDisabledTooltip(
              `overflowingText-reportee-${item.email}`
            )}
          >
            <div
              id={`overflowingText-reportee-${item.email}`}
              className={`${BASE_CLASS}-user-containerUser
            ${isAllowUserClick(item) && "cursor"}`}
              onClick={() => isAllowUserClick(item) && setSelectedUser(item)}
            >
              <div className={`${BASE_CLASS}-user-image-card`}>
                <img src={UserIconWarn} alt="user-img" />
              </div>
              <div className={`${BASE_CLASS}-user`}>
                <div className={`${BASE_CLASS}-user-details`}>
                  <div className="user-name ellipsis2 break-word-white-space">
                    {item.name}
                  </div>
                  <div className="user-email ellipsis dds-label-sm-light">
                    {item.email}
                  </div>
                  <div className={`${BASE_CLASS}-jobTitle-container`}>
                    <div
                      className={`${BASE_CLASS}-jobTitle ellipsis dds-label-sm-light`}
                    >
                      {item.jobTitle}
                    </div>
                    {item?.reportees?.length ? (
                      <div
                        className={`${BASE_CLASS}-user-details-countCard-container`}
                      >
                        <div
                          className={`${BASE_CLASS}-user-details-countCard dds-label-sm`}
                        >
                          + {item?.reportees?.length}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3.2} className={`${BASE_CLASS}-body-row-col`}>
          {format_HR_MIN_SEC((item?.swipeInOut || 0) / 1000)}
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3} className={`${BASE_CLASS}-body-row-col`}>
          {item?.billableHours ? `${item?.billableHours}hr` : "NA"}
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-divider`} />
        </GridItem>
        <GridItem xs={3} className={`${BASE_CLASS}-body-row-col`}>
          {format_HR_MIN_SEC(item?.totalHrs || 0)}
        </GridItem>
        <GridItem
          xs={17}
          style={{ width: "100%" }}
          className={`${BASE_CLASS}-body-row-col`}
          onClick={() => handleProgressbarClick(item)}
        >
          {getProgressBar(item, isCLickable)}
        </GridItem>
      </Grid>
    </Fragment>
  );

  const getUserCard = (item: IUser, i: number, isCLickable = true) => {
    return (
      <Grid
        columns={33}
        className={`${BASE_CLASS}-body-row-empty`}
        style={{ padding: "0 0vw", gap: "0px" }}
      >
        <GridItem xs={6.97} className={`${BASE_CLASS}-body-row-col `}>
          <Tooltip
            toolTipText={
              <>
                {checkNameTooltip(`overflowingText-user-${item.email}`) && (
                  <div>{item.name}</div>
                )}
                {checkEmailTooltip(`overflowingText-user-${item.email}`) && (
                  <div>{item.email}</div>
                )}
                {checkJobTitleTooltip(`overflowingText-user-${item.email}`) && (
                  <div>{item.jobTitle}</div>
                )}
              </>
            }
            tooltipPosition={PositionState.right}
            tooltipInvokeType={InvokeType.hover}
            tooltipWidth={"max-content"}
            tooltipIsDisabled={checkDisabledTooltip(
              `overflowingText-user-${item.email}`
            )}
          >
            <div
              id={`overflowingText-user-${item.email}`}
              className={`${BASE_CLASS}-user-container ${
                isAllowUserClick(item) && "cursor"
              }`}
              onClick={() => isAllowUserClick(item) && setSelectedUser(item)}
            >
              <div
                className={`${BASE_CLASS}-user-image`}
                style={{
                  backgroundColor: COLORSlIST[i % COLORSlIST.length],
                }}
              >
                {item?.name &&
                  item?.name?.charAt(0) +
                    item?.name?.split(" ")?.pop()?.charAt(0)}
              </div>
              <div className={`${BASE_CLASS}-user ${BASE_CLASS}-user-selected`}>
                <div className={`${BASE_CLASS}-user-details`}>
                  <div className="user-name ellipsis2 break-word-white-space">
                    {item.name}
                  </div>
                  <div className="user-email ellipsis dds-label-sm-light">
                    {item.email}
                  </div>

                  <div className={`${BASE_CLASS}-jobTitle-container`}>
                    <div
                      className={`${BASE_CLASS}-jobTitle ellipsis dds-label-sm-light`}
                    >
                      {item.jobTitle}
                    </div>
                    {item?.reportees?.length ? (
                      <div
                        className={`${BASE_CLASS}-user-details-countCard-container`}
                      >
                        <div
                          className={`${BASE_CLASS}-user-details-countCard dds-label-sm`}
                        >
                          + {item?.reportees?.length}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </GridItem>
        <GridItem>
          <div className={`${BASE_CLASS}-body-row-cardDivider`} />
        </GridItem>
        <GridItem xs={26} style={{ marginBottom: "3vw" }}>
          <Grid
            columns={33}
            className={`${BASE_CLASS}-title-row dds-label `}
            style={{ padding: "1vw" }}
          >
            <GridItem xs={5} className={`${BASE_CLASS}-title-row-col`}>
              Clocked in time
            </GridItem>
            <GridItem xs={4.5} className={`${BASE_CLASS}-title-row-col`}>
              Billable hours
            </GridItem>
            <GridItem xs={4} className={`${BASE_CLASS}-title-row-col`}>
              Total hours
            </GridItem>
            <GridItem xs={21} className={`${BASE_CLASS}-title-row-col`} />
          </Grid>

          <Grid
            columns={33}
            className={`${BASE_CLASS}-body-row dds-body`}
            style={{ padding: "1vw" }}
          >
            <GridItem xs={5} className={`${BASE_CLASS}-body-row-col`}>
              {format_HR_MIN_SEC((item?.swipeInOut || 0) / 1000)}
            </GridItem>
            <GridItem xs={4.5} className={`${BASE_CLASS}-body-row-col`}>
              {item?.billableHours ? `${item?.billableHours}hr` : "NA"}
            </GridItem>
            <GridItem xs={4} className={`${BASE_CLASS}-body-row-col`}>
              {format_HR_MIN_SEC(item?.totalHrs || 0)}
            </GridItem>
            <GridItem
              xs={21}
              style={{ width: "100%" }}
              className={`${BASE_CLASS}-body-row-col`}
              onClick={() => {
                if (isCLickable) handleProgressbarClick(item);
              }}
            >
              {getProgressBar(item, isCLickable)}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    );
  };

  const getLegends = () => {
    return (
      <div className={`${BASE_CLASS}-legends-container`}>
        {legendsData.map((item) => (
          <div
            key={item.label}
            className={`${BASE_CLASS}-legends-legend dds-body`}
          >
            <div
              className={`${BASE_CLASS}-legends-legend-box`}
              style={{
                backgroundColor: item.color,
              }}
            />
            {item.label === "Uncategorized" ? "Uncategorised" : item.label}
          </div>
        ))}
      </div>
    );
  };

  const checkOverflow = (textContainer: any): boolean => {
    const el: HTMLDivElement | any = textContainer;
    if (el) {
      return (
        el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth
      );
    }
    return false;
  };

  const checkNameTooltip = (id: string): boolean =>
    checkOverflow(
      document.getElementById(id)?.getElementsByClassName("user-name")[0]
    );

  const checkEmailTooltip = (id: string): boolean =>
    checkOverflow(
      document.getElementById(id)?.getElementsByClassName("user-email")[0]
    );

  const checkJobTitleTooltip = (id: string): boolean =>
    checkOverflow(
      document
        .getElementById(id)
        ?.getElementsByClassName("teamLevel-jobTitle")[0]
    );

  const checkDisabledTooltip = (id: string): boolean => {
    if (
      checkNameTooltip(id) ||
      checkEmailTooltip(id) ||
      checkJobTitleTooltip(id)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setRefresh(!refresh);
  }, [modalUser]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          gap: "20px",
        }}
      >
        <div
          className={`${BASE_CLASS}-daterange`}
          key={`${BASE_CLASS}-daterange`}
        >
          <DateRangePicker
            size={Size.l}
            theme={themeObjState}
            maxDate={baseDate}
            labelPosition="external"
            label={"Start and end date"}
            disabledDates={{ after: new Date(), dates: [new Date()] }}
            isManualInput={false}
            format="DD/MM/YYYY"
            icon="calendar__s__stroke"
            customClass="daterange"
            value={[dateRange.startDate, dateRange.endDate]}
            dateRangeSelected={(data: string[] | Date[] | null) => {
              if (data && data.length === 2) {
                setDateRange({
                  startDate: new Date(data[0]),
                  endDate: new Date(data[1]),
                });
              }
            }}
          />
        </div>
      </div>
      <div className={`${BASE_CLASS}-divider`} />

      <div className={`${BASE_CLASS}-header dds-h6`}>Organisation chart</div>
      <NotifyToast
        type={"INFO"}
        message={
          "Click on the profile card to view the drill-down chart of your team."
        }
      />
      <div className={`${BASE_CLASS}-graph-title-container fs-20`}>
        {userManagers?.map((item: any, i: number) => (
          <Tooltip
            toolTipText={
              <>
                {checkNameTooltip(`overflowingText-${item.email}`) && (
                  <div>{item.name}</div>
                )}
                {checkEmailTooltip(`overflowingText-${item.email}`) && (
                  <div>{item.email}</div>
                )}
                {checkJobTitleTooltip(`overflowingText-${item.email}`) && (
                  <div>{item.jobTitle}</div>
                )}
              </>
            }
            tooltipPosition={PositionState.right}
            tooltipInvokeType={InvokeType.hover}
            tooltipWidth={"max-content"}
            tooltipIsDisabled={checkDisabledTooltip(
              `overflowingText-${item.email}`
            )}
          >
            <Fragment key={item.email}>
              <div
                id={`overflowingText-${item.email}`}
                className={`${BASE_CLASS}-manager-container ${
                  isAllowManagerCLick(item) && "cursor"
                }`}
                style={{
                  border:
                    selectedUser.email === item.email
                      ? "2px solid #0D8390"
                      : "",
                }}
                onClick={() => {
                  if (modalUser?.appsData) {
                    setModalUser(undefined);
                  }
                  isAllowManagerCLick(item) && setSelectedUser(item);
                }}
              >
                <div
                  className={`${BASE_CLASS}-manager-image`}
                  style={{
                    backgroundColor: COLORSlIST[i % COLORSlIST.length],
                  }}
                >
                  {item.name.charAt(0) + item.name.split(" ").pop().charAt(0)}
                </div>
                <div className={`${BASE_CLASS}-manager`}>
                  <div className="user-name ellipsis2 break-word-white-space">
                    {item.name}
                  </div>
                  <div className="user-email ellipsis dds-label-sm-light">
                    {item.email}
                  </div>
                  <div className={`${BASE_CLASS}-jobTitle-container`}>
                    <div
                      className={`${BASE_CLASS}-jobTitle ellipsis dds-label-sm-light`}
                    >
                      {item.jobTitle}
                    </div>
                    {item?.reportees?.length ? (
                      <div
                        className={`${BASE_CLASS}-manager-details-count-container`}
                      >
                        <div
                          className={`${BASE_CLASS}-manager-details-count  dds-label-sm`}
                        >
                          + {item?.reportees?.length}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className={`${BASE_CLASS}-manager-arrow`} />
            </Fragment>
          </Tooltip>
        ))}
      </div>
      <div className={`${BASE_CLASS}-body ${BASE_CLASS}-body-scroll`}>
        {modalUser?.appsData ? (
          <>
            <div className={`${BASE_CLASS}-header dds-h6`}>
              Employee app usage
            </div>
            <div
              className={`${BASE_CLASS}-back cursor`}
              onClick={() => setModalUser(undefined)}
            >
              <BackIcon />
              Back
            </div>
            {getUserCard(modalUser, 0, false)}
            <div className={`${BASE_CLASS}-divider`} />
            <div className={`${BASE_CLASS}-header dds-h6`}>App usage</div>

            <UserApps
              appsData={modalUser.appsData
                .filter((item: any) => item)
                .sort(
                  (a: { duration: number }, b: { duration: number }) =>
                    b.duration - a.duration
                )}
              legends={legendsData}
              categoriesData={modalUser?.categoriesData}
              isLoggedUser={LoginUserData.email === modalUser.email}
            />
          </>
        ) : (
          <>
            <div className={`${BASE_CLASS}-header dds-h6`}>
              Employee drill-down
            </div>

            {loginUserEvents?.[0] && (
              <>{getUserCard(loginUserEvents[0], 0, true)}</>
            )}

            <div className={`${BASE_CLASS}-divider`} />

            {getReportees().length !== 0 ? getHeaderHtml() : <></>}
            {(isSortAsc
              ? getReportees()?.slice().reverse()
              : getReportees()
            )?.map(
              (item: IUser, i) =>
                viewLength > i && getUserDetailHtml(item, i + 1)
            )}
            {viewLength < directReportees.length && (
              <div className={`${BASE_CLASS}-addMore`}>
                <Button
                  size={Size.m}
                  kind={Kind.primary}
                  theme={themeObjState}
                  width="fixed"
                  label="View all"
                  onClick={() => {
                    setViewLength(directReportees.length);
                  }}
                />
              </div>
            )}
          </>
        )}
        {getLegends()}
      </div>
    </div>
  );
};

export default TeamLevel;
