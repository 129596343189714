import React, { FC, useState, useRef, useEffect } from "react";
import "./Tooltip.scss";

import {
  TooltipOptions,
  InvokeType,
  TooltipType,
  PositionState,
  TooltipClass,
} from "./typings";

const Tooltip: FC<TooltipOptions> = (props: TooltipOptions) => {
  const overflowingText = useRef<HTMLSpanElement | null>(null);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const {
    toolTipText,
    tooltipInvokeType = InvokeType.hover,
    tooltipType = TooltipType.regular,
    tooltipPosition = PositionState.top,
    tooltipWidth,
    tooltipHasBeak = true,
    tooltipIsDisabled,
    id,
    custStyle,
    children,
    customclass = "",
    tooltipMaxWidth,
    tooltipIsDynamic,
  } = props;
  const ref = useRef<any>(null);

  let timeout: any;
  const [active, setActive] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    const el: HTMLDivElement | any = textContainer?.firstChild;
    if (el) {
      return (
        el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth
      );
    }
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  const showTip = () => {
    if (
      !tooltipIsDisabled &&
      (!tooltipIsDynamic || (tooltipIsDynamic && overflowActive))
    ) {
      timeout = setTimeout(() => {
        setActive(true);
      }, 100);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    const handleClick = (e: any) => {
      if (ref?.current.contains(e.target)) return;
      hideTip();
      setIsClick(false);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  function checkBeak(position: PositionState | string): string {
    switch (position) {
      case PositionState.top:
      case PositionState.topLeft:
      case PositionState.topRight:
        return PositionState.top;
      case PositionState.bottom:
      case PositionState.bottomLeft:
      case PositionState.bottomRight:
        return PositionState.bottom;
      case PositionState.left:
      case PositionState.leftBottom:
      case PositionState.leftTop:
        return PositionState.left;
      case PositionState.right:
      case PositionState.rightBottom:
      case PositionState.rightTop:
        return PositionState.right;
      default:
        return "";
    }
  }

  return (
    <div
      ref={ref}
      data-testid="tooltip"
      id={id}
      style={custStyle}
      className="tooltip-wrapper"
      onMouseEnter={() => {
        (tooltipInvokeType === InvokeType.hover ||
          tooltipInvokeType === InvokeType.hoverClick) &&
          showTip();
      }}
      onMouseLeave={() => {
        (tooltipInvokeType === InvokeType.hover ||
          tooltipInvokeType === InvokeType.hoverClick) &&
          hideTip();
      }}
      onClick={() => {
        (tooltipInvokeType === InvokeType.click ||
          tooltipInvokeType === InvokeType.hoverClick) &&
          setIsClick(!isClick);
      }}
    >
      <span ref={overflowingText}>{children}</span>
      {(active || isClick) && (
        <div
          data-testid="tooltip text"
          className={`${TooltipClass.tooltip} ${
            TooltipClass.tooltip
          }_${checkBeak(tooltipPosition)}${
            !tooltipHasBeak ? " no-beak" : ""
          } beak_${tooltipPosition}${
            tooltipType === TooltipType.error
              ? ` ${TooltipClass.error} ${TooltipClass.error}_${checkBeak(
                  tooltipPosition
                )}`
              : ""
          } ${customclass}`}
          style={{
            width: tooltipWidth || "auto",
            maxWidth: tooltipMaxWidth || tooltipWidth || "auto",
          }}
        >
          {toolTipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
