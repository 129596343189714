import { HTMLProps } from 'react';
// import cn from "classnames";
import './PageLink.scss';

export type Props = HTMLProps<HTMLAnchorElement> & { active?: boolean };

export default function PageLink({ className, active, disabled, children, ...otherProps }: Props) {
  const customClassName = `page-link no_highlights dds-body${className ? ' ' + className : ''}${
    active ? ' page-link-active' : ''
  }${disabled ? ' page-link-disabled' : ''}`;

  if (disabled) {
    return <span className={customClassName}>{children}</span>;
  }

  return (
    <a className={customClassName} aria-current={active ? 'page' : undefined} {...otherProps}>
      {children}
    </a>
  );
}
