export enum roleEnum {
  MASTER = "master",
  USER = "user",
  ENTITLED = "entitled",
}

export enum approvalStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export const StatusObj: Record<string, string> = {
  [approvalStatusEnum.APPROVED]: "Approved",
  [approvalStatusEnum.PENDING]: "Approval pending",
  [approvalStatusEnum.REJECTED]: "Rejected",
};
