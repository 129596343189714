import axios from "axios";
// import querystring from "querystring";
// import { Navigate, redirect, useNavigate } from "react-router-dom";
import { keycloakURL, keycloakSSOLoginURL, webappURL } from "serviceClient";

let accessToken: any = null;
let refreshToken: any = null;

const getAuthenticationURL = () => {
  return keycloakSSOLoginURL;
};

const ssoLogin = async (code: any) => {
  // console.log(query);
  const exchangeOptions = {
    grant_type: "authorization_code",
    client_id: "pd-client",
    code: code,
    redirect_uri: webappURL,
  };

  const options = {
    method: "POST",
    url: `${keycloakURL}/protocol/openid-connect/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: new URLSearchParams(exchangeOptions).toString(),
  };

  // console.log('))))', options);
  try {
    const response = await axios(options);
    // console.log(response);
    accessToken = response.data.access_token;
    refreshToken = response.data.refresh_token;
    // console.log('access token: ', accessToken);
    // console.log('refresh token: ', refreshToken);
    window.localStorage["accessToken"] = accessToken;
    window.localStorage["refreshToken"] = refreshToken;
    const kcuserdata = JSON.parse(window.atob(accessToken.split(".")[1]));
    window.localStorage["kcUserData"] = JSON.stringify(kcuserdata);
    return true;
    // setTimeout(() => {
    //   logout();
    // }, 3000);
  } catch (error) {
    console.log("this error", error);
    window.localStorage.clear();
    window.location.reload();
    return false;
  }
};

const loadTokens = async (username: string, password: string) => {
  const exchangeOptions = {
    grant_type: "password",
    client_id: "pd-client",
    username: username,
    password: password,
  };

  const options = {
    method: "POST",
    url: `${keycloakURL}/protocol/openid-connect/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: new URLSearchParams(exchangeOptions).toString(),
  };

  // console.log('))))', options);
  try {
    const response = await axios(options);
    // console.log(response);
    accessToken = response.data.access_token;
    refreshToken = response.data.refresh_token;
    // console.log('access token: ', accessToken);
    // console.log('refresh token: ', refreshToken);
    window.localStorage["accessToken"] = accessToken;
    window.localStorage["refreshToken"] = refreshToken;
    const kcuserdata = JSON.parse(window.atob(accessToken.split(".")[1]));
    window.localStorage["kcUserData"] = JSON.stringify(kcuserdata);
    return true;
    // setTimeout(() => {
    //   logout();
    // }, 3000);
  } catch (error) {
    console.log("this error", error);
    return false;
  }
};

async function logout() {
  const redirect = encodeURIComponent(webappURL);
  const logoutparams = {
    client_id: "pd-client",
    refresh_token: window.localStorage["refreshToken"],
    post_logout_redirect_uri: redirect,
  };

  const logoutoptions = {
    method: "POST",
    url: `${keycloakURL}/protocol/openid-connect/logout`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${refreshToken}`,
    },
    data: new URLSearchParams(logoutparams).toString(),
  };
  try {
    const logoutresponse = await axios(logoutoptions);

    console.log("logged out successfully", logoutresponse);
  } catch (error) {
    console.log("logout error!", error);
  }
}
async function refreshTokens() {
  // console.log("refresh token: ", window.localStorage['refreshToken']);
  const refreshparams = {
    grant_type: "refresh_token",
    client_id: "pd-client",
    refresh_token: window.localStorage["refreshToken"],
  };
  const refreshOptions = {
    method: "POST",
    url: `${keycloakURL}/protocol/openid-connect/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${window.localStorage["refreshToken"]}`,
    },
    data: new URLSearchParams(refreshparams).toString(),
  };
  try {
    const response = await axios(refreshOptions);
    // console.log("refreshtoken response: ", response);
    accessToken = response.data.access_token;
    refreshToken = response.data.refresh_token;
    // console.log("tokens refreshed !!")
    // console.log('access token: ', accessToken);
    // console.log('refresh token: ', refreshToken);
    window.localStorage["accessToken"] = accessToken;
    window.localStorage["refreshToken"] = refreshToken;
    const kcuserdata = JSON.parse(window.atob(accessToken.split(".")[1]));
    window.localStorage["kcUserData"] = JSON.stringify(kcuserdata);
    return response;
  } catch (error: any) {
    console.log("refresh tokens error", error);
    window.localStorage.clear();
    window.location.reload();
  }
}
const services = {
  ssoLogin,
  loadTokens,
  logout,
  refreshTokens,
  getAuthenticationURL,
};

export default services;
