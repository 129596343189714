import axios from "axios";
// import serverDetails from "./serverDetails";
// import serverDetails from "./serverDetails-dev";
// import serverDetails from "./serverDetails-uat";
import AuthService from "services/AuthService";

// import serverDetails from './serverDetails-dev';
// import keycloak from './Keycloak';
// import { request } from "http";

function getToken() {
  // console.log("kc:", keycloak);
  // console.log("token:", keycloak.token);
  return window.localStorage["accessToken"];
}

const usersClient = axios.create({
  baseURL: process.env.REACT_APP_users + "/userserviceapi/v1.0",
});

const categoriesClient = axios.create({
  baseURL: process.env.REACT_APP_categories + "/categoryserviceapi/v1.0",
});

const appsClient = axios.create({
  baseURL: process.env.REACT_APP_apps + "/categoryserviceapi/v1.0",
});

usersClient.interceptors.request.use(
  (request) => {
    if (request.params) {
      request.params = {
        ...request.params,
        encryptedUserDetails: btoa(localStorage.getItem("encRecord") || ""),
      };
    } else {
      request.params = {
        encryptedUserDetails: btoa(localStorage.getItem("encRecord") || ""),
      };
    }
    request.headers!["Authorization"] = "Bearer " + getToken();
    request.headers!["Content-type"] = "application/json";
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

categoriesClient.interceptors.request.use(
  (request) => {
    if (request.params) {
      request.params = {
        ...request.params,
        encryptedUserDetails: btoa(localStorage.getItem("encRecord") || ""),
      };
    } else {
      request.params = {
        encryptedUserDetails: btoa(localStorage.getItem("encRecord") || ""),
      };
    }
    request.headers!["Authorization"] = "Bearer " + getToken();
    request.headers!["Content-type"] = "application/json";
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

appsClient.interceptors.request.use(
  (request) => {
    console.log("interceptor working");
    request.params = {
      ...request.params,
      encryptedUserDetails: btoa(localStorage.getItem("encRecord") || ""),
    };
    request.headers!["Authorization"] = "Bearer " + getToken();
    request.headers!["Content-type"] = "application/json";
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// projectsClient.interceptors.request.use(
//   (request) => {
//     console.log('interceptor working');
//     request.headers!['Authorization'] = 'Bearer ' + getToken();
//     request.headers!['Content-type'] = 'application/json';
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// processesClient.interceptors.request.use(
//   (request) => {
//     console.log('interceptor working');
//     request.headers!['Authorization'] = 'Bearer ' + getToken();
//     request.headers!['Content-type'] = 'application/json';
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// usersClient.interceptors.request.use(
//   (request) => {
//     console.log('interceptor working');
//     request.headers!['Authorization'] = 'Bearer ' + getToken();
//     request.headers!['Content-type'] = 'application/json';
//     return request;
//   },
//   (error) => {
//     //try refresh token
//     console.log('refreshing token');
//   }
// );

// repositoriesClient.interceptors.request.use(
//   (request) => {
//     console.log('interceptor working');
//     request.headers!['Authorization'] = 'Bearer ' + getToken();
//     request.headers!['Content-type'] = 'application/json';
//     return request;
//   },
//   (error) => {
//     //try refresh token
//     console.log('refreshing token');
//   }
// );

const errorHandler = async (error: any) => {
  console.log("error handler", error);
  try {
    // const refreshToken =
    await AuthService.refreshTokens();
    error.config.headers["Authorization"] =
      "Bearer " + window.localStorage["accessToken"];
    return axios(error.config);
  } catch (err) {
    console.log("error occured", err);
  }
  // refresh tokens
  //if successfully refreshed resend request and store token
  // error.config.headers['Authorization'] = 'Bearer ' + //returned token
  // return axios.request(error.config)
  // catch error -> homepage

  // return keycloak
  //   .updateToken(60)
  //   .then((_) => {
  //     console.log('successfully get a new token', keycloak.token);
  //     error.config.headers['Authorization'] = 'Bearer ' + keycloak.token;
  //     // error.config.baseURL = undefined;
  //     return axios.request(error.config);
  //   })
  //   .catch((err) => Promise.reject(error));

  return Promise.reject(error);
};

appsClient.interceptors.response.use(
  (response) => {
    if (/<\/?[a-z][\s\S]*>/i.test(response.data)) {
      return errorHandler(response);
    } else {
      return response;
    }
  },
  (error) => errorHandler(error)
);
categoriesClient.interceptors.response.use(
  (response) => {
    if (/<\/?[a-z][\s\S]*>/i.test(response.data)) {
      return errorHandler(response);
    } else {
      return response;
    }
  },
  (error) => errorHandler(error)
);

usersClient.interceptors.response.use(
  (response) => {
    if (/<\/?[a-z][\s\S]*>/i.test(response.data)) {
      return errorHandler(response);
    } else {
      return response;
    }
  },
  (error) => errorHandler(error)
);
// projectsClient.interceptors.response.use(
//   (response) => response,
//   (error) => errorHandler(error)
// );
// projectsClient.interceptors.response.use(
//   (response) => response,
//   (error) => errorHandler(error)
// );
// const keycloakClient = axios.create({
//   baseURL:
//     serverDetails.keycloak.scheme +
//     "://" +
//     serverDetails.keycloak.url +
//     "/realms/pd-realm",
// });

const keycloakURL = process.env.REACT_APP_keycloak + "/realms/pd-realm";

const keycloakSSOLoginURL =
  process.env.REACT_APP_keycloak +
  "/realms/pd-realm/protocol/openid-connect/auth?" +
  "scope=openid profile&response_type=code&client_id=pd-client&" +
  `redirect_uri=${encodeURIComponent(
    process.env.REACT_APP_Webapp!
  )}&kc_idp_hint=oidc`;

// const webappURL = serverDetails.webapp.url;
const webappURL = process.env.REACT_APP_Webapp;

const powerbiURL = process.env.REACT_APP_powerbi;

export {
  categoriesClient,
  appsClient,
  usersClient,
  keycloakURL,
  keycloakSSOLoginURL,
  webappURL,
  powerbiURL,
};
