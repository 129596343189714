import { Input, Size } from "@usitsdasdesign/dds-react";
import { Dropdown, InfoIcon } from "global";
import { FC, useState } from "react";

export interface ICategoryChangeProps {
  id: string;
  fromCat: string;
  fromSubCat: string;
  catOptions: any;
  changeCat: (item: any) => void;
  isInfo?: boolean;
  tosubCat: any;
}

const CategoryChange: FC<ICategoryChangeProps> = ({
  id,
  fromCat,
  fromSubCat,
  catOptions,
  changeCat,
  isInfo,
  tosubCat,
}) => {
  const [subcategoriesOpt, setSubcategoriesOpt] = useState<any>([]);
  return (
    <div
      className="--formItem"
      id="CurrentCategory"
      style={{ margin: "10px 0px 10px 0px" }}
      data-testid="categoryChange"
    >
      {isInfo && (
        <div className="dds-label" style={{ display: "flex", gap: "1vh" }}>
          <img src={InfoIcon} />
          URLs/apps categorisation is specific to the selected filter
        </div>
      )}
      {id !== "uncategorizedapps" && (
        <>
          <Input
            isDisabled={true}
            label="Current category"
            size={Size.m}
            value={fromCat}
          />
          <Input
            isDisabled={true}
            label="Current subcategory"
            size={Size.m}
            value={fromSubCat}
          />
        </>
      )}
      <Dropdown
        className="width-100"
        isMulti={false}
        placeholderStyle={{}}
        showValue={true}
        placeholder="Select from categories"
        isSearchable={true}
        options={catOptions}
        defaultValue={""}
        labelText={
          id !== "uncategorizedapps" ? "Update category" : "Select category"
        }
        isRequired={true}
        addLabel={true}
        onchange={(item: any) => {
          changeCat({});
          setSubcategoriesOpt(
            item?.subcategories
              ?.filter((cat: any) => cat.id !== id)
              ?.map((cat: any) => ({
                ...cat,
                label: cat.name,
                value: cat.id,
              }))
              ?.sort((a: any, b: any) => a.name.localeCompare(b.name)) || []
          );
        }}
        controlShouldRenderValue={false}
        isClearable={false}
        dropdownIndicatorStyle={{ display: "none" }}
        containerStyle={{ display: "flex", gap: "2px" }}
        isSearchBox={true}
      />
      <Dropdown
        className="width-100"
        isMulti={false}
        placeholderStyle={{}}
        showValue={true}
        placeholder="Select from subcategories"
        isSearchable={true}
        disabled={subcategoriesOpt?.length === 0}
        options={subcategoriesOpt}
        defaultValue={tosubCat}
        labelText={
          id !== "uncategorizedapps"
            ? "Update subcategory"
            : "Select subcategory"
        }
        isRequired={true}
        addLabel={true}
        onchange={(item) => {
          changeCat(item);
        }}
        controlShouldRenderValue={false}
        isClearable={false}
        dropdownIndicatorStyle={{ display: "none" }}
        containerStyle={{ display: "flex", gap: "2px" }}
        isSearchBox={true}
      />
    </div>
  );
};
export default CategoryChange;
